import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoggingService } from '../../services/logging.service';
import { OPTGDashboardService } from '../../services/optg-dashboard.service';
import { DashboardData } from '@jmttg/dynamic-dashboard/lib/models/dashboard-data.model';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AppModule } from 'src/app/app.module';
import { DashboardComponent } from '@jmttg/dynamic-dashboard';
import { FormsModule } from '@angular/forms';
import { Dashboard } from '../../../app/models/dashboard.model';
import { WidgetStoreComponent } from '../widget-store/widget-store.component';
import { EmployeeDirectoryComponent } from "../employee-directory/employee-directory.component";
import { UserDashboardConfig } from '../../../app/models/user-dashboard-config.model';
import { TasksTabContainerComponent } from "../tasks/tasks-tab-container.component";
import { ProjectFlagRenderer } from "../ag-grid/renderers/project-flag/project-flag-renderer.component";
import { DueDateRenderer } from '../ag-grid/renderers/duedate/due-date-renderer.component';
import { finalize, Subscription } from 'rxjs';

const WIDGET_TYPE_IFRAME = 'iframe';

@Component({
  selector: 'optg-dashboard',
  templateUrl: './optg-dashboard.component.html',
  styleUrls: ['./optg-dashboard.component.scss'],
  standalone: true,
  imports: [NgxChartsModule, AppModule, DashboardComponent, FormsModule, WidgetStoreComponent, EmployeeDirectoryComponent, TasksTabContainerComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OPTGDashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  public entities = [];
  public dashboardNames: any[] = [];
  public dashboardConfig = signal<UserDashboardConfig>({config: {}} as UserDashboardConfig);
  public dashboardFilter: string | null = null;
  dashboardDataFilters: any[] = [];
  public dashboardData = signal<DashboardData>({ "counts": [], "lists": [] });

  private subscriptions: Subscription[] = [];

  public frameworkComponents = {
    'ProjectFlagRenderer': ProjectFlagRenderer,
    'DueDateRenderer': DueDateRenderer
  }
  widgetCount = 0;

  public dashboard: Dashboard = {config:{}, data:{"counts": [], "lists": []} as DashboardData} as Dashboard;
  public templates: {[key: string]: TemplateRef<any>} = {};

  public displayDashboard: boolean = true;

  @ViewChild('employeeDirectory')
  employeeDirectory!: TemplateRef<any>;

  @ViewChild('tasksTabContainer')
  tasksTabContainer!: TemplateRef<any>;

  constructor(
    private dashboardService: OPTGDashboardService,
    private loggingService: LoggingService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.displayDashboard = false;
      var dashboardIdParam = params['id'];
      this.dashboardFilter = params['filter'];
      if (this.dashboardData()) {
        this.dashboardData.update(data => ({...data, counts: [], lists: []}));
      }
      this.dashboardData.update(data => ({...data, counts: [], lists: []}));

      this.cdr.detectChanges();
      this.displayDashboard = true;

      this.cdr.detectChanges();

      this.dashboardService.getEntities().then(res => {
        this.entities = res;
      }, err => {
        this.loggingService.logError("Error getting entities " + err);
      });
      if (dashboardIdParam) {
        this.getDashboard(dashboardIdParam);
      } else
      {
        //TODO: Reimplement post-MVP when have multiple dashboard configurations per user functionality
        /*const getDashboardConfigsSub = this.dashboardService.getDashboardConfigs().pipe(finalize(() => {
          this.subscriptions.push(getDashboardConfigsSub);
        })).subscribe((result) => {
          this.getDashboard(result[0]);
        });*/

        this.getDashboard();
      }
    });
  }

  ngAfterViewInit(): void {
    let templates: {[key: string]: TemplateRef<any>} = {}
    templates['employeeDirectory'] = this.employeeDirectory;
    templates['tasksTabContainer'] = this.tasksTabContainer;
    this.templates = templates;
  }

  getDashboard(dashboardId?: string | null) {
    this.dashboardDataFilters = [];
    const getDashboardConfigSub = this.dashboardService.getDashboardConfig(dashboardId).pipe(finalize(() => {
      this.subscriptions.push(getDashboardConfigSub);
    })).subscribe(dc => {
      this.dashboard.config = JSON.parse(dc.config);
      this.dashboard.config.config.widgets.forEach((w: any) => {
        if (w.config.columns) {
          w.config.columns.forEach((c:any) => {
            if (c.cellRenderer) {
              //@ts-ignore
              c.cellRenderer = this.frameworkComponents[c.cellRenderer];
            }
          });
        }
      });
      this.dashboardDataFilters = dc.dataFilters;
      this.grabWidgetData();
      console.log('final config', this.dashboardConfig);
    }, err => {
    });
  }

  grabWidgetData() {
    if (this.dashboardConfig()) {
      let dashboardConfig = this.dashboard.config.config;
      console.log('dashC', JSON.stringify(dashboardConfig));
      for (let i = 0; i < dashboardConfig.widgets.length; i++) {
        var dc = dashboardConfig.widgets[i];
        if (this.dashboardDataFilters && this.dashboardDataFilters.length) {
          this.grabSpecificData(dc, this.dashboardDataFilters);
        } else {
          this.grabSpecificData(dc);
        }
      }
    }
  }

  grabSpecificData(dc: any, dataFilters:any[] = []) {
    console.log('dc', dc);
    if (dataFilters && dataFilters.length) {
      var filterSeparator = encodeURI('^^');
      var eqop = encodeURI('||');
      if (this.dashboardFilter) {
        let filterdatas = this.dashboardFilter.split(filterSeparator);
        var filledFilters = [];
        for (let i = 0; i < filterdatas.length; i++) {
          let fd = filterdatas[i];
          let fdname = fd.split("||")[0];
          let fdval = fd.split("||")[1];
          let filter = this.dashboardDataFilters.find(x => x.filterColumn == fdname);
          if (filter) {
            filter.stringValue = fdval;
            filledFilters.push(filter);
          }
          console.log('filterdata', fdname, fdval);

        }
        if (filledFilters.length > 0) {
          const getFilteredEntityData = this.dashboardService.getFilteredEntityData(dc.dataKey, filledFilters).pipe(finalize(() => {
            this.subscriptions.push(getFilteredEntityData);
          })).subscribe(res => {
            this.dataResponse(dc, res);
          }, err => {
            this.loggingService.logError("Error getting filtered data for " + dc.dataKey);
          });
        }
      } else {
        console.error("no filter found for dashboard");
      }
    } else if (dc.dataKey) {
      console.log(`calling getFullEntity with dataKey: ${dc.dataKey}`);
      const getFullEntityDataSub = this.dashboardService.getFullEntityData(dc.dataKey).pipe(finalize(() => {
        this.subscriptions.push(getFullEntityDataSub);
      })).subscribe(res => {
        this.dataResponse(dc, res);
      }, err => {
        this.loggingService.logError("Error getting data for " + dc.dataKey);
      });
    } else if (dc.type === WIDGET_TYPE_IFRAME) {
      const iframeKey = dc.config?.iframeConfig?.templateName ? dc.config?.iframeConfig?.templateName : dc.config?.iframeConfig?.src;
      if (!this.dashboard.data?.lists.some(x => x.Key == iframeKey)) {
        this.dashboard.data?.lists.push({Key: iframeKey, Value: WIDGET_TYPE_IFRAME});
        this.dataResponse(dc, null);
      }

    }
  }

  dataResponse(dc: any, res: any) {
    console.log('res', res);
    if (this.dashboard.data) {
      if (dc.type === "stat") {
        var value = this.getValueForStatWidget(dc.config, res);
        let existingKeys = this.dashboard.data?.counts.filter(d => d.Key.startsWith(dc.dataKey)).length;
        if (existingKeys && existingKeys > 0) {
          dc.dataKey = `${dc.dataKey}_${existingKeys}`; //necessary as dynamic dashboard relies on key name to match data to widget config, causing titles & position to misfire if two widgets have the same key
        }
        this.dashboard.data?.counts.push({ Key: dc.dataKey, Value: value });
        console.log('dd', this.dashboard.data);
        console.log('this', this);
      } else if (dc.type !== WIDGET_TYPE_IFRAME) {
        let existingKeys = this.dashboard.data?.lists.filter(d => d.Key.startsWith(dc.dataKey)).length;
        if (existingKeys && existingKeys > 0) {
          dc.dataKey = `${dc.dataKey}_${existingKeys}`; //necessary as dynamic dashboard relies on key name to match data to widget config, causing titles & position to misfire if two widgets have the same key
        }
        this.dashboard.data.lists.push({ Key: dc.dataKey, Value: res });
        console.log('dd', this.dashboard.data);
        console.log('this', this);
      }
    }
    //Prevent unnecessary loading of dashboards before all widget data has been retrieved.   NOTE: Service requests should be combined in another future commit to no longer need this.
    if ((this.dashboard.data.counts.length + this.dashboard.data.lists.length) >= this.dashboard.config.config.widgets.length) {
      this.dashboardConfig.set(this.dashboard.config);
      this.dashboardData.set(this.dashboard.data);
      this.dashboard.config = {config: {}} as UserDashboardConfig;
      this.dashboard.data = {counts: [], lists: []};
    }
  }

  onDashboardUpdated(dashboardId: string): void {
    if (this.dashboardData()) {
      this.dashboardData.set({"counts": [], "lists": []});
    }
    this.getDashboard(dashboardId);
  }

  public getValueForStatWidget(config: any, data: any) {
    if (config.type === "numresults") {
      return data.length;
    }
    if (config.type == "firstrow" && config.column && data && data.length>0) {
      return data[0][config.column];
    }
    console.log('getValueForStatWidget', config, data);
    return -99.999;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
