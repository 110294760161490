import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class LoggingService {

  constructor(private http: HttpClient
  ) { }

  logDebug(message: string): Observable<any> {
    return this.postLog('debug', message);
  }
  logInfo(message: string): Observable<any> {
    return this.postLog('info', message);
  }
  logWarning(message: string): Observable<any> {
    console.warn("Log message: " + message);
    return this.postLog('warning', message);
  }
  logError(message: string): Observable<any> {
    console.error("Log message: " + message);
    //this.toastr.error(message);
    return this.postLog('error', message);
  }

  private postLog(level: string, message: string): Observable<any> {
    return this.http.post<any>(`/api/log/${level}`, { value: message });
  }
}
