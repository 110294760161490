import { Routes } from "@angular/router";
import { HomeComponent } from "./app/components/home/home.component";
import { OPTGDashboardComponent } from "./app/components/dashboard/optg-dashboard.component";

export const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'dashboard/:id', component: OPTGDashboardComponent, title: 'Dashboard Home' },
  { path: 'dashboard/:id/:filter', component: OPTGDashboardComponent },
  { path: 'employee-directory', loadComponent: () => import('./app/components/employee-directory/employee-directory.component').then(x => x.EmployeeDirectoryComponent), title: 'Directory'},
  { path: 'team', loadComponent: () => import('./app/components/team/team.component').then(x => x.TeamComponent), title: 'My Team'},
  { path: 'projects', loadComponent: () => import('./app/components/projects/projects.component').then(x => x.ProjectsComponent), title: 'My Projects' },
  { path: 'marketing', loadComponent: () => import('./app/components/marketing/marketing.component').then(x => x.MarketingComponent), title: 'Marketing' },
  { path: 'tasks', loadComponent: () => import('./app/components/tasks/tasks-tab-container.component').then(x => x.TasksTabContainerComponent), title: 'Tasks'}
];
