
  <section>
    <h3>Directory</h3>

    <section>
      <fieldset class="m-0 p-1">
        <legend id="searchLegend">Search by:</legend>
        <div class="flex align-items-center" role="search" arial-labeled-by="searchLegend" data-filter-value="">
          <label class="flex align-items-center"><input type="radio" value="name" [(ngModel)]="searchType"
              #ctrl="ngModel" (change)="searchTypeChanged($event)" />Employee Name</label>
          <label class="flex align-items-center"><input type="radio" value="office" [(ngModel)]="searchType"
              #ctrl="ngModel" (change)="searchTypeChanged($event)" />Office Location</label>
          <label class="flex align-items-center"><input type="radio" value="practice" [(ngModel)]="searchType"
              #ctrl="ngModel" (change)="searchTypeChanged($event)" />Practice</label>
        </div>
      </fieldset>
    </section>
    
    <div *ngIf="searchType === 'name'">
      <ng-template #typeaheadResultsTemplate let-r="result" let-t="term">
        <ngb-highlight [result]="r.displayName" [term]="t"></ngb-highlight>
        <div class="m-t--25">{{r.jobCodeTitle}}</div>
        <div class="m-t--25">{{r.department}}</div>
      </ng-template>
      <input class="search-text"
        id="typeahead-format"
        type="text"
        [ngbTypeahead]="searchEmployee"
        (selectItem)="onSelectEmployee($event)"
        [resultFormatter]="employeeFormatter"
        [inputFormatter]="employeeFormatter"
        [resultTemplate]="typeaheadResultsTemplate"
        placeholder="Begin typing, 2 characters minimum..." />
    </div>
    <div *ngIf="searchType === 'office'">
      <ng-template #typeaheadResultsTemplate let-r="result" let-t="term">
        <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
      </ng-template>
      <input class="search-text"
        id="typeahead-format"
        type="text"
        [ngbTypeahead]="searchOffice"
        (selectItem)="onSelectOffice($event)"
        [resultFormatter]="officeFormatter"
        [inputFormatter]="officeFormatter"
        [resultTemplate]="typeaheadResultsTemplate"
        placeholder="Begin typing, 2 characters minimum..." />
    </div>
    <div *ngIf="searchType === 'practice'">
      <ng-template #typeaheadResultsTemplate let-r="result" let-t="term">
        <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
      </ng-template>
      <input class="search-text"
        id="typeahead-format"
        type="text"
        [ngbTypeahead]="searchPractice"
        (selectItem)="onSelectPractice($event)"
        [resultFormatter]="practiceFormatter"
        [inputFormatter]="practiceFormatter"
        [resultTemplate]="typeaheadResultsTemplate"
        placeholder="Begin typing, 2 characters minimum..." />
    </div>
</section>
<section *ngIf="searchType === 'name'" class="employee-list-container m-t-1">
  <div *ngIf="supervisor;else no_direct_supervisor" class="employee-list">
    <ol class="no-numbers small-indent">
      <li class="p-t--25 p-b--25">
        <employee-list-item [employee]="supervisor" (selectedEmployee)="changeSelectedEmployee($event)"></employee-list-item>
        <ol class="no-numbers small-indent">
          @for (emp of employeesIncludingSelf; track emp) {
            <li class="p-t--25 p-b--25">
              <employee-list-item [employee]="emp" (selectedEmployee)="changeSelectedEmployee($event)"></employee-list-item>
              
              <ol class="no-numbers small-indent">
                @for (directReport of directReports; track directReport) {
                  <li class="p-t--25 p-b--25">
                    <employee-list-item [employee]="directReport" (selectedEmployee)="changeSelectedEmployee($event)"></employee-list-item>
                  </li>
                }
              </ol>
            </li>
          }
        </ol>
      </li>
    </ol>
  </div>
  <!-- No direct supervisor -->
  <ng-template #no_direct_supervisor>
    <div class="employee-list">
      <ol class="no-numbers small-indent">
        @for (emp of employeesIncludingSelf; track emp) {
          <li class="p-t--25 p-b--25">
            <employee-list-item [employee]="emp" (selectedEmployee)="changeSelectedEmployee($event)"></employee-list-item>
            
            <ol class="no-numbers small-indent">
              @for (directReport of directReports; track directReport) {
                <li class="p-t--25 p-b--25">
                  <employee-list-item [employee]="directReport" (selectedEmployee)="changeSelectedEmployee($event)"></employee-list-item>
                </li>
              }
            </ol>
          </li>
        }
      </ol>
    </div>
  </ng-template>
  <employee-details [selectedEmployee]="selectedEmployee" [directReportsEmails]="directReportsEmails" 
    (onSelectOffice)="loadOffice($event)" (onSelectDepartment)="loadPractice($event)"></employee-details>
</section>
<section *ngIf="searchType === 'office'" class="employee-list-container m-t-1">
  <div class="employee-list-and-filter-holder">
    <div class="filter-holder p--50 flex align-items-center">
      <svg-icon-sprite [src]="'../../assets/sprites/sprite.svg#icon-office'" [width]="'32px'" [viewBox]="'0 0 31 32'"></svg-icon-sprite>
      <div class="p-l--50">{{selectedOffice}}</div>
      <select [ngClass]="[ practiceFilterSelected ? 'filter filter-icon selected' : 'filter filter-icon']" [(ngModel)]="practiceFilter" (change)="onPracticeSelect($event)">
        <option class="filter-option" value="">Select One</option>
        @for (p of practices; track p) {
          <option class="filter-option" [value]="p.name">{{p.name}}</option>
          }
      </select>
    </div>
    <div class="employee-list">
      <ol class="no-numbers small-indent">
        @for (emp of employees; track emp) {
          <li class="p-t--25 p-b--25">
            <employee-list-item [employee]="emp" (selectedEmployee)="changeSelectedEmployee($event)"></employee-list-item>
          </li>
        }
      </ol>
    </div>
  </div>
  
  <employee-details [selectedEmployee]="selectedEmployee" [selectedOffice]="selectedOffice" 
    [officeLeader]="officeLeader" [directReportsEmails]="directReportsEmails" (onSelectOffice)="loadOffice($event)" (onSelectDepartment)="loadPractice($event)"></employee-details>
</section>

<section *ngIf="searchType === 'practice'" class="employee-list-container m-t-1">
  <div class="employee-list-and-filter-holder">
    <div class="filter-holder p--50 flex align-items-center">
      <svg-icon-sprite [src]="'../../assets/sprites/sprite.svg#icon-practice'" [width]="'32px'" [viewBox]="'0 0 31 32'"></svg-icon-sprite>
      <div class="p-l--50">{{selectedPractice}}</div>
      <select [ngClass]="[ officeFilterSelected ? 'filter filter-icon selected' : 'filter filter-icon']" [(ngModel)]="officeFilter" (change)="onOfficeSelect($event)">
        <option class="filter-option" value="">Select One</option>
        @for (o of offices; track o) {
          <option class="filter-option" [value]="o.name">{{o.name}}</option>
          }
      </select>
    </div>
    <div class="employee-list">
      <ol class="no-numbers small-indent">
        @for (emp of employees; track emp) {
          <li class="p-t--25 p-b--25">
            <employee-list-item [employee]="emp" (selectedEmployee)="changeSelectedEmployee($event)"></employee-list-item>
          </li>
        }
      </ol>
    </div>
  </div>
  <employee-details [selectedEmployee]="selectedEmployee" [directReportsEmails]="directReportsEmails" 
    [practiceLeader]="practiceLeader"
    [selectedPractice]="selectedPractice" (onSelectOffice)="loadOffice($event)" (onSelectDepartment)="loadPractice($event)"></employee-details>
</section>