@if (isGoDecision) {
  <span class="image-cell-renderer-container">
    <svg-icon-sprite [src]="'../../assets/sprites/sprite.svg#icon-greencheck'" [width]="'24px'" [height]="'20px'" [viewBox]="'0 0 32 32'"></svg-icon-sprite>
  </span>
} @else {
  <div [class.pastdue]="pastDue" [class.normal]="!pastDue">
    @if (pastDue) {
      <svg-icon-sprite [src]="'../../assets/sprites/sprite.svg#icon-ppr-red-flag-for-grid'" [width]="'16px'" [viewBox]="'0 0 16 16'"></svg-icon-sprite>
    }
    {{dateString}}
  </div>
}

