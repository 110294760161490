import { AppModule } from '../../../app.module';
import { CommonModule, formatDate } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { LoggingService } from '../../../services/logging.service';
import { ToastrService } from 'ngx-toastr';
import { OPTGDashboardService } from '../../../services/optg-dashboard.service';
import { DATE_FORMAT_STRING, DATE_LOCALE_STRING } from '../../../models/constants.model';

@Component({
  selector: 'attic',
  standalone: true,
  imports: [AppModule, CommonModule],
  templateUrl: './attic.component.html',
  styleUrl: './attic.component.scss'
})
export class AtticComponent {
  public employee: any = null;
  public showEarningsPaid: boolean = false;
  public showEmployeePaidBenefits: boolean = false;
  public showEmployeePaidTaxes: boolean = false;
  public showTotalCompensation: boolean = false;
  private subscription: any;
  protected readonly ATTIC_DATA_ENTITY_NAME = "nexus.vw_AtticData";

  public constructor(
    private dashboardService: OPTGDashboardService,
    private loggingService: LoggingService,
    private toastr: ToastrService,
    private cd: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.getEmployeeAtticData();
  }

  private getEmployeeAtticData() {
    this.subscription = this.dashboardService.getFullEntityData(this.ATTIC_DATA_ENTITY_NAME).subscribe(res => {
      this.employee = res[0];
      if (this.employee) {
        this.formatData();
        this.cd.detectChanges();
      }
    }, err => {
      this.loggingService.logError("Error retrieving attic data").subscribe();
      window.setTimeout(() => { this.toastr.error("Error retrieving attic data."); }, 500);
    });
  }

  private formatData() {
    if (this.employee.lastHireDate) {
      this.employee.lastHireDate = formatDate(this.employee.lastHireDate, DATE_FORMAT_STRING, DATE_LOCALE_STRING);
    }
    this.validateAndFormatNumber("ptoEarned", " hours");
    this.validateAndFormatNumber("ptoBalance", " hours");
    this.validateAndFormatNumber("fhEarned", " hours");
    this.validateAndFormatNumber("fhBalance", " hours");
    if (this.employee.weekStartDate) {
      this.employee.weekStartDate = formatDate(this.employee.weekStartDate, DATE_FORMAT_STRING, DATE_LOCALE_STRING);
    }
    if (this.employee.weekEndDate) {
      this.employee.weekEndDate = formatDate(this.employee.weekEndDate, DATE_FORMAT_STRING, DATE_LOCALE_STRING);
    }
    if (this.employee.weekStartDate && this.employee.weekEndDate) {
      this.employee.currentTimesheetPeriod = this.employee.weekStartDate + " - " + this.employee.weekEndDate;
    }
    this.validateAndFormatNumber("totalHours", " hours");
    this.validateAndFormatNumber("timesheetUtilizationPercent", "%");
    this.validateAndFormatNumber("utilizationPercent", "%");
    this.validateAndFormatNumber("utilizationGoalPercent", "%");
  }

  private validateAndFormatNumber(propertyName: string, suffix: string): void {
    if (this.employee[propertyName] && !isNaN(this.employee[propertyName])) {
      this.employee[propertyName] = parseInt(this.employee[propertyName]) + suffix;
    }
  }

  public toggleShowEarningsPaid(): void {
    this.showEarningsPaid = !this.showEarningsPaid;
  }

  public toggleEmployeePaidBenefits(): void {
    this.showEmployeePaidBenefits = !this.showEmployeePaidBenefits;
  }

  public toggleEmployeePaidTaxes(): void {
    this.showEmployeePaidTaxes = !this.showEmployeePaidTaxes;
  }

  public toggleTotalCompensation(): void {
    this.showTotalCompensation = !this.showTotalCompensation;
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  
}
