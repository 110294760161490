import { ICellRenderer } from "ag-grid-enterprise";
import { Component, ElementRef, OnDestroy, ViewChild } from "@angular/core";
import { IImageCellRendererParams } from "../../models/iimage-cell-renderer-params";
import { CommonModule } from "@angular/common";

@Component({
  selector: 'display-large-string-modal-renderer',
  styleUrls: ['./display-large-string-modal-renderer.component.scss'],
  templateUrl: 'display-large-string-modal-renderer.component.html',
  standalone: true,
  imports: [CommonModule]
})
export class DisplayLargeStringModalRenderer implements ICellRenderer<IImageCellRendererParams>, OnDestroy {
  public text!: string;
  public viewTitle!: string;

  //TODO: May want to have some of these values be configurable
  public displayableCharLimit: number = 54;
  public displayableCharLimitWithLinkOffset: number = 39;

  private readonly CHARS_PER_PIXEL_WIDTH: number = 0.25;
  private readonly HEIGHT_PIXELS_PER_CHARS_LINE: number = 30;
  private readonly MAX_HEIGHT_PIXELS: number = 400;
  private readonly BUTTON_HEIGHT_WITH_PADDING_PIXELS: number = 45;
  private readonly OVERLAY_CONTAINER_CLASS_SELECTOR = '.overlay-container';

  private openModals: string[] = [];

  @ViewChild("dialog") dialog!: ElementRef;

  agInit(params: IImageCellRendererParams): void {
    if (params && params.colDef && params.colDef.field && params.data && params.data[params.colDef.field] != null) {
      this.text = params.data[params.colDef.field];
      this.viewTitle = `View all of the ${params.colDef.headerName}`;
    }
  }
  refresh(params: any): boolean {
    return true;
  }

  public openModal(event: any): void {
    const buttonPosition = event.target.getBoundingClientRect() as DOMRect;
    let dialogElement = document.createElement('dialog');
    dialogElement.id = `modal_${new Date().getTime()}`;
    this.openModals.push(dialogElement.id);
    dialogElement.style.setProperty('left', `${buttonPosition.left}px`);
    dialogElement.style.setProperty('right', `${buttonPosition.right}px`);
    dialogElement.style.setProperty('top', `${buttonPosition.top}px`);
    dialogElement.style.setProperty('bottom', `${buttonPosition.bottom}px`);
    dialogElement.style.setProperty('padding', '5px');

    const parentCellDivPosition = event.target?.parentElement?.parentElement?.getBoundingClientRect() as DOMRect;
    if (parentCellDivPosition) {
      const charsPerLine = Math.round(this.CHARS_PER_PIXEL_WIDTH * (parentCellDivPosition.width - 10));
      const roundedCharsLines = Math.round(this.text.length / charsPerLine);
      const charsLines = roundedCharsLines > 0 ? roundedCharsLines : 1;
      const estimatedHeightPixels = (this.HEIGHT_PIXELS_PER_CHARS_LINE * charsLines) + this.BUTTON_HEIGHT_WITH_PADDING_PIXELS;

      const heightPixels = estimatedHeightPixels > this.MAX_HEIGHT_PIXELS ? this.MAX_HEIGHT_PIXELS : estimatedHeightPixels;
      dialogElement.style.setProperty('width', `${parentCellDivPosition.width}px`);
      dialogElement.style.setProperty('height', `${heightPixels}px`);
    }

    let textElement = Object.assign(document.createElement('span'), {className: 'long-text'});
    textElement.innerText = `${this.text}\n\n`;
    dialogElement.appendChild(textElement);
    const closeButtonWrapperElement = Object.assign(document.createElement('div'), {className: 'close-button-wrapper'});
    const button = Object.assign(document.createElement('button'), {className: 'secondary close-button'});
    button.addEventListener('click', (event: any) => {
      const matchingModalId = event.target.parentElement.id;
      const modalElement = document.getElementById(matchingModalId);
      if (modalElement) {
        document.querySelector(this.OVERLAY_CONTAINER_CLASS_SELECTOR)?.removeChild(modalElement);
        this.openModals.splice(this.openModals.indexOf(matchingModalId), 1);
      }
      dialogElement.close();
    });
    button.innerText = 'Close';
    closeButtonWrapperElement.appendChild(button);
    dialogElement.appendChild(closeButtonWrapperElement);
    document.querySelector(this.OVERLAY_CONTAINER_CLASS_SELECTOR)?.appendChild(dialogElement);

    dialogElement.showModal();
  }

  public ngOnDestroy(): void {
    this.openModals.forEach((modalId: string) => {
      const matchingModal = document.getElementById(modalId);
      if (matchingModal) {
        document.querySelector(this.OVERLAY_CONTAINER_CLASS_SELECTOR)?.removeChild(matchingModal);
      }
    });
    this.openModals = [];
  }
}
