import { Component, OnInit, input, OnDestroy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule, CurrencyPipe, DatePipe, formatDate } from '@angular/common';
import { AppModule } from '../../app.module';
import { GridFormatterService } from '@jmttg/ag-grid-library';
import { ColDef } from 'ag-grid-community';
import { BaseTasksComponent } from './base-tasks.component';
import { OPTGDashboardService } from '../../../app/services/optg-dashboard.service';
import { AppSourceImageRendererComponent } from '../ag-grid/renderers/app-source-image-renderer.component';
import { DATE_FORMAT_STRING, DATE_LOCALE_STRING } from 'src/app/models/constants.model';
import { IImageCellRendererParams } from '../ag-grid/models/iimage-cell-renderer-params';
import { ArrowImageLinkRendererComponent } from '../ag-grid/renderers/arrow-image-link-renderer.component';
import { TaskInProgressData } from '../../../app/models/task-in-progress-data.model';
import { AgGridModule } from 'ag-grid-angular';

@Component({
  standalone: true,
  selector: 'tasks-in-progress',
  templateUrl: './tasks-in-progress.component.html',
  imports: [AgGridModule, AppModule, CommonModule, FormsModule],
  providers: [GridFormatterService, DatePipe, CurrencyPipe]
})
export class TasksInProgressComponent extends BaseTasksComponent<TaskInProgressData> implements OnInit, OnDestroy {
  displayHeader = input<boolean>(true);


  constructor(protected gridFormatterService: GridFormatterService, protected optgDashboardService: OPTGDashboardService) {
    super(gridFormatterService, optgDashboardService);
    this.entityName = this.TASKS_IN_PROGRESS_ENTITY_NAME;
  }

  protected buildColumns(): Array<ColDef> {
    const defaultDateFilterParams = this.getDefaultDateFilterParams();
    const defaultTextFilterParams = this.getDefaultTextFilterParams();

    let colDefs: Array<ColDef> = [];

    colDefs.push({
      colId: this.APP_SOURCE_COLUMN_ID,
      headerName: "",
      headerTooltip: "Application",
      field: 'appSource',
      filter: false,
      suppressMenu: true,
      flex: 0.25,
      minWidth: 75,
      maxWidth: 75,
      headerClass:'wrap-header-text',
      cellRenderer: AppSourceImageRendererComponent
    } as ColDef);

    colDefs.push({
      colId: this.SUBMITTED_DATE_COLUMN_ID,
      headerName: "Date Submitted",
      headerTooltip: "Submitted Date",
      field: "submittedDate",
      flex: 1,
      minWidth: 130,
      maxWidth: 140,
      wrapHeaderText: true,
      menuTabs: ['filterMenuTab'],
      filter: "agDateColumnFilter",
      filterParams: defaultDateFilterParams,
      valueFormatter: (params) => {
        if (params.value) {
          //Converts date to user's local timezone from UTC
          return formatDate(`${params.value}Z`, DATE_FORMAT_STRING, DATE_LOCALE_STRING);
        } else {
          return "";
        }
      }
    } as ColDef);

    colDefs.push({
      colId: this.TASK_NAME_COLUMN_ID,
      headerName: "Task",
      headerTooltip: "Task Name",
      field: "taskName",
      flex: 2,
      minWidth: 325,
      maxWidth: 550,
      menuTabs: ['filterMenuTab'],
      filter: "agTextColumnFilter",
      filterParams: defaultTextFilterParams,
      valueFormatter: (params) => {
        if (params.value && params.data) {
          if (params.data.dueDate) {
            //Converts date to user's local timezone from UTC
            return `${params.value} - Due ${formatDate(`${params.data.dueDate}Z`, DATE_FORMAT_STRING, DATE_LOCALE_STRING)}`;
          } else {
            return params.value;
          }
        } else {
          return "";
        }
      }
    } as ColDef);

    colDefs.push({
      colId: this.RESPONSIBILITY_COLUMN_ID,
      headerName: "Responsibility",
      headerTooltip: "Task's Responsibility",
      field: "responsibility",
      flex: 2,
      minWidth: 200,
      maxWidth: 300,
      menuTabs: ['filterMenuTab'],
      filter: "agTextColumnFilter",
      filterParams: defaultTextFilterParams
    } as ColDef);

    colDefs.push({
      colId: this.URL_COLUMN_ID,
      headerName: "",
      headerTooltip: "Select the button to navigate to the task",
      field: 'url',
      filter: false,
      suppressMenu: true,
      flex: 0.25,
      minWidth: 75,
      maxWidth: 75,
      sortable: false,
      headerClass:'wrap-header-text',
      cellRenderer: ArrowImageLinkRendererComponent,
      cellRendererParams: {
        imageTitle: 'View this task'
      } as IImageCellRendererParams
    } as ColDef);
    return colDefs;
  }

  protected sortData(data: any, sortModel: any): void {
    let allData: TaskInProgressData[] = data as TaskInProgressData[];

    for (let i = 0; i < sortModel.length; i++) {
      const currentSortColumn = sortModel[i];
      if (currentSortColumn.colId == this.RESPONSIBILITY_COLUMN_ID) {
        if (currentSortColumn.sort.toLowerCase() == this.SORT_ORDER_ASCENDING) {
          allData.sort((a, b) => (a.responsibility ? a.responsibility : '') < (b.responsibility ? b.responsibility : '') ? -1 : 1);
        } else {
          allData.sort((a, b) => (a.responsibility ? a.responsibility : '') > (b.responsibility ? b.responsibility : '') ? -1 : 1);
        }
      }
      if (currentSortColumn.colId == this.SUBMITTED_DATE_COLUMN_ID) {
        if (currentSortColumn.sort.toLowerCase() == this.SORT_ORDER_ASCENDING) {
          allData.sort((a, b) => a.submittedDate < b.submittedDate ? -1 : 1);
        } else {
          allData.sort((a, b) => a.submittedDate > b.submittedDate ? -1 : 1);
        }
      }
      if (currentSortColumn.colId == this.TASK_NAME_COLUMN_ID) {
        if (currentSortColumn.sort.toLowerCase() == this.SORT_ORDER_ASCENDING) {
          allData.sort((a, b) => a.taskName < b.taskName ? -1 : 1);
        } else {
          allData.sort((a, b) => a.taskName > b.taskName ? -1 : 1);
        }
      }

      if (currentSortColumn.colId == this.APP_SOURCE_COLUMN_ID) {
        if (currentSortColumn.sort.toLowerCase() == this.SORT_ORDER_ASCENDING) {
          allData.sort((a, b) => a.appSource < b.appSource ? -1 : 1);
        } else {
          allData.sort((a, b) => a.appSource > b.appSource ? -1 : 1);
        }
      }
    }
  }
}
