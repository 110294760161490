import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PendingDashboard } from "../models/pending-dashboard.model";
import { DashboardWidgetConfig } from '../models/dashboard-widget-config.model';

@Injectable({
  providedIn: 'root'
})
export class OPTGDashboardService {
  private entities = [];
  constructor(private http: HttpClient) { }

  public getEntities(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.entities && this.entities.length) {
        resolve(this.entities);
      } else {
        this.http.get<any>('/api/config').subscribe((response: any) => {
          this.entities = response;
          resolve(this.entities);
        }, (error: any) => {
          reject(error);
        });
      }
    });
  }

  public getDashboardConfigs(): Observable<any>
  {
    return this.http.get<any>('/api/config/dashboardConfigs')
  }

  public getDashboardConfig(id?: string | null) {
    let urlSuffix = "";
    if (id) {
      urlSuffix = `dashboardConfig/${id}`;
    } else {
      urlSuffix = 'default-dashboardConfig';
    }
    return this.http.get<any>(`/api/config/${urlSuffix}`);
  }
  public getFullEntityData(name: string) {
    return this.http.get<any>(`/api/data/getFullEntity/${name}`);
  }

  public getFilteredEntityData(name: string, dataFilters: any[]) {
    return this.http.post<any>(`/api/data/getFilteredEntity/${name}`, dataFilters);
  }

  public getAvailableWidgets(dashboardId: string): Observable<DashboardWidgetConfig[]> {
    return this.http.get<DashboardWidgetConfig[]>(`/api/config/available-widgets/${dashboardId}`);
  }

  public saveDashboard(dashboard: PendingDashboard): Observable<boolean> {
    return this.http.post<boolean>('/api/config/save-dashboard', dashboard);
  }

  public deleteDashboardWidgets(dashboardId: string, isCustom: boolean = true): Observable<boolean> {
    return this.http.delete<boolean>(`/api/config/delete-dashboard/${dashboardId}/${isCustom}`);
  }
}
