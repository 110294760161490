import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';

@Component({
  selector: 'employee-details',
  standalone: true,
  imports: [CommonModule, IconSpriteModule],
  templateUrl: './employee-details.component.html',
  styleUrl: './employee-details.component.scss'
})
export class EmployeeDetailsComponent {
  public _selectedEmployee: any;
  public _selectedOffice: any;
  public _officeLeader: any;
  public _selectedPractice: any;
  public _practiceLeader: any;

  @Input() directReportsEmails: string = "";

  @Input() set selectedEmployee (value: any) {
    if (value) {
      this._selectedPractice = null;
      this._selectedOffice = null;
      this._selectedEmployee = value;
    }
  }
  @Input() set selectedOffice (value: any) {
    if (value) {
      this._selectedEmployee = null;
      this._selectedPractice = null;
      this._selectedOffice = value;
    }
  }
  @Input() set officeLeader (value: any) {
    if (value) {
      this._selectedPractice = null;
      this._officeLeader = value;
    } else {
      this._officeLeader = null;
    }
  }
  @Input() set selectedPractice (value: any) {
    if (value) {
      this._selectedEmployee = null;
      this._selectedOffice = null;
      this._selectedPractice = value;
    }
  }
  @Input() set practiceLeader (value: any) {
    if (value) {
      this._selectedOffice = null;
      this._practiceLeader = value;
    } else {
      this._practiceLeader = null;
    }
  }

  @Output() onSelectDepartment = new EventEmitter<any>();
  @Output() onSelectOffice = new EventEmitter<any>();

  selectDepartment() {
    this.onSelectDepartment.emit(this);
  }

  selectOffice() {
    this.onSelectOffice.emit(this);
  }
}
