import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { LoggingService } from '../../services/logging.service';
import { RouterModule } from '@angular/router';
import { CommonModule, DOCUMENT } from '@angular/common';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { AtticComponent } from './attic/attic.component';


@Component({
  selector: 'nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
  standalone: true,
  imports: [RouterModule, CommonModule, IconSpriteModule, AtticComponent]
})

export class NavMenuComponent implements OnInit {
  public shortcutsVisible: boolean = false;
  public atticShowing: boolean = false;
  public userInfo: any = null;
  public userGreeting: string = "";

  public constructor(
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService,
    private loggingService: LoggingService,
    private cdf: ChangeDetectorRef
  ) {
    this.document.addEventListener('click', (e) => {
      this.shortcutsVisible = false;
      this.cdf.detectChanges();
    });
  }

  ngOnInit(): void {
    this.authService.getSlimUserInfo().then((res: any) => {
      this.userInfo = res;
      this.userGreeting = this.getUserGreeting();
      this.cdf.markForCheck();
    }, (err: any) => {
        this.loggingService.logError("Error getting slim user info: " + err);
    });
  }

  getUserGreeting(): string {
    const date = new Date();
    const hour = date.getHours();
    let greeting;
    const fullName = this.userInfo.name;
    let firstName = "";

    if (hour < 12) {
      greeting = "Good morning";
    } else if (hour < 18) {
      greeting = "Good afternoon";
    } else {
      greeting = "Good evening";
    }
    
    if (fullName) {
      if (fullName.split(',').length > 1) {
        firstName = fullName.split(',')[1].trim();
      } else {
        firstName = fullName;
      }
      return greeting + ", " + firstName;
    }
    return "";
  }

  toggleShortcuts(event: any) {
    event.stopPropagation();
    this.shortcutsVisible = !this.shortcutsVisible;
  }

  public toggleMenuHamburger(event: any): void {
    console.log(event.target)
    event.stopPropagation();
    this.shortcutsVisible = !this.shortcutsVisible;
  }

  public toggleAttic(): void {
    this.atticShowing = !this.atticShowing;
  }
}
