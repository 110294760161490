@if (text && text.length > displayableCharLimit) {
  <span>
    {{text | slice: 0 : displayableCharLimitWithLinkOffset }}...
  </span>
  <button class="link-button view-button" (click)="openModal($event)" [title]="viewTitle">View</button>
} @else {
  {{text}}
}


