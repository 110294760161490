<section class="attic-content">
    <div class="attic-content-item">
        <div>
            <h2>{{employee?.displayName}}</h2>
            <ul class="reset">
                <li>
                    <label>
                        Corporate Title:
                        <span>{{employee?.corporateTitle}}</span>
                    </label>
                </li>
                <li>
                    <label>
                        Title:
                        <span>{{employee?.jobCodeTitle}}</span>
                    </label>
                </li>
                <li>
                    <label>
                        Department:
                        <span>{{employee?.department}}</span>
                    </label>
                </li>
                <li>
                    <label>
                        Employee Number:
                        <span>{{employee?.employeeNumber}}</span>
                    </label>
                </li>
                <li>
                    <label>
                        Hire Date:
                        <span>{{employee?.lastHireDate}}</span>
                    </label>
                </li>
            </ul>
        </div>
    </div>
    <hr>
    <div class="attic-content-item">
        <ul class="reset">
            <li>
                <label>
                    Manager:
                    <span>{{employee?.supervisorDisplayName}}</span>
                </label>
            </li>
            <li>
                <label>
                    Office Location:
                    <span>{{employee?.office}}</span>
                </label>
            </li>
            <li>
                <label>
                    Office Code:
                    <span>{{employee?.officeCode}}</span>
                </label>
            </li>
        </ul>
    </div>
    <hr>
    <div class="attic-content-item">
        <fieldset>
            <legend>Paid Time Off</legend>
            <ul class="reset">
                <li>
                    <label>
                        Earned Balance:
                        <span>{{employee?.ptoEarned}}</span>
                    </label>
                </li>
                <li>
                    <label>
                        Balance Available:
                        <span>{{employee?.ptoBalance}}</span>
                    </label>
                </li>
                <li>
                    <label>
                        Floating Earned:
                        <span>{{employee?.fhEarned}}</span>
                    </label>
                </li>
                <li>
                    <label>
                        Floating Available:
                        <span>{{employee?.fhBalance}}</span>
                    </label>
                </li>
            </ul>
        </fieldset>
        <a href="https://ultipro.jmt.com/" target="_blank" class="attic-button-link m-t--50">Launch UKG Pro</a>
    </div>
    <hr>
    <div class="attic-content-item">
        <fieldset>
            <legend>Timesheet</legend>
            <ul class="reset">
                <li>
                    <label>
                        Current Period:
                        <span>{{employee?.currentTimesheetPeriod}}</span>
                    </label>
                </li>
                <li>
                    <label>
                        Total Hours:
                        <span>{{employee?.totalHours}}</span>
                    </label>
                </li>
                <li>
                    <label>
                        Utilization:
                        <span>{{employee?.timesheetUtilizationPercent}}</span>
                    </label>
                </li>
                <li>
                    <label>
                        YTD Utilization:
                        <span>{{employee?.utilizationPercent}}</span>
                    </label>
                </li>
                <li>
                    <label>
                        Annual Utilization Goal:
                        <span>{{employee?.utilizationGoalPercent}}</span>
                    </label>
                </li>
            </ul>
        </fieldset>
        <a href="https://jmt.operations.dynamics.com/?mi=PMBIProjectTasksWorkspace&disable365shell=true&cmp=JMT" target="_blank" class="attic-button-link m-t--50">Launch Timesheet</a>
    </div>
    <!--
    <hr>
    <div class="attic-content-item">
        <fieldset>
            <legend>Compensation</legend>
            <ul class="reset">
                <li>
                    <label>
                        Earnings Paid:
                        <button *ngIf="!showEarningsPaid" class="link-button" (click)="toggleShowEarningsPaid()">Show</button>
                        <span *ngIf="showEarningsPaid"></span>
                    </label>
                </li>
                <li>
                    <label>
                        Employee Paid Benefits:
                        <button *ngIf="!showEmployeePaidBenefits" class="link-button" (click)="toggleEmployeePaidBenefits()">Show</button>
                        <span *ngIf="showEmployeePaidBenefits"></span>
                    </label>
                </li>
                <li>
                    <label>
                        Employer Paid Taxes:
                        <button *ngIf="!showEmployeePaidTaxes" class="link-button" (click)="toggleEmployeePaidTaxes()">Show</button>
                        <span *ngIf="showEmployeePaidTaxes"></span>
                    </label>
                </li>
                <li>
                    <label>
                        Total Compensation:
                        <button *ngIf="!showTotalCompensation" class="link-button" (click)="toggleTotalCompensation()">Show</button>
                        <span *ngIf="!showTotalCompensation"></span>
                    </label>
                </li>
            </ul>
        </fieldset>
        <a href="https://ew34.ultipro.com/" target="_blank" class="attic-button-link m-t--50">Launch UKG Pro</a>
    </div>
    -->
  </section>
