import { Component, OnInit, signal } from '@angular/core';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { RouterOutlet } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { LoggingService } from '../services/logging.service';
import { DragService } from '../services/drag.service';
import { provideAnimations } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { OPTGDashboardComponent } from './dashboard/optg-dashboard.component';
import { HomeComponent } from './home/home.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet, NavMenuComponent, NgxChartsModule, OPTGDashboardComponent, HomeComponent],
  providers: [AuthService, LoggingService, DragService, provideAnimations()]
})
export class AppComponent implements OnInit {
  title = 'app';

  public displayHeader = signal<boolean>(true);

  constructor() {}

  public ngOnInit(): void {
    let params = new URL(window.location.toString())?.searchParams;
    if (params?.get('hideHeader')?.toLowerCase() == 'true') {
      this.displayHeader.set(false);
    }
  }
}
