import { Component, signal } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { LoggingService } from '../../services/logging.service';
import { OPTGDashboardComponent } from '../dashboard/optg-dashboard.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  imports: [OPTGDashboardComponent, CommonModule],
  standalone: true
})
export class HomeComponent {
  public userInfo = signal<any>(null);

  public constructor(
    private authService: AuthService,
    private loggingService: LoggingService
  ) {

  }
  ngOnInit(): void {
    this.authService.getUserInfo().then((res: any) => {
      this.userInfo.set(res);
    }, (err: any) => {
      this.loggingService.logError("Error getting user info: " + err);
    });
  }
}
