import { ICellRenderer } from "ag-grid-enterprise";
import { Component } from "@angular/core";
import { IconSpriteModule } from "ng-svg-icon-sprite";
import { IImageCellRendererParams } from "../../models/iimage-cell-renderer-params";

@Component({
  selector: 'due-date-renderer',
  styles: ".pastdue {margin-left: 10px;color: red}; .normal {margin-left: 27px;}",
  templateUrl: 'due-date-renderer.component.html',
  standalone: true,
  imports: [IconSpriteModule]
})
export class DueDateRenderer implements ICellRenderer<IImageCellRendererParams> {

  params!: any;
  public dateData!: string;
  public dateString!: string;

  public url!: string;
  public title!: string;
  public pastDue!: boolean;
  public isGoDecision: boolean = false;

  private isGoNoGoDate: boolean = false;

  private readonly GO_NO_GO_DUE_DATE_FIELD: string = 'goNoGoDecisionDueDateTime';
  private readonly GO_DECISION: string = 'GO';

  agInit(params: IImageCellRendererParams): void {
    this.title = params?.imageTitle;
    this.url = params?.data?.url;
    if (params && params.colDef && params.colDef.field && params.data && params.data[params.colDef.field] != null) {
      this.dateData = params.data[params.colDef.field];
      //Handle data dates as UTC timezone
      var d = new Date(this.dateData + 'Z');
      this.dateString = d.toLocaleDateString();
      //TODO: Will want functionality in dashboard package for AgGrid widget to distinguish between date and dateTime if needed.  Will default to check date without time.
      let dateStringInLocalTimezone = new Date(params.value + 'Z').toDateString();
      this.pastDue = (new Date(new Date().toDateString()) > this.addDays(dateStringInLocalTimezone, -7));

      this.isGoNoGoDate = params.colDef.field == this.GO_NO_GO_DUE_DATE_FIELD;
      if (this.isGoNoGoDate && params.data?.decision) {
        this.isGoDecision = params.data.decision.toUpperCase() == this.GO_DECISION;
      }

    }
  }
  addDays(date:string, days:number) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  refresh(params: any): boolean {
    return true;
  }
}
