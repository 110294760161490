import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { GridFormatterService } from "@jmttg/ag-grid-library";
import { ColDef, GridOptions, IDatasource, IDateFilterParams, IGetRowsParams, INumberFilterParams, ITextFilterParams } from "ag-grid-community";
import { finalize, Subscription } from "rxjs";
import { OPTGDashboardService } from "../../../app/services/optg-dashboard.service";
import { MyTaskData } from "../../../app/models/my-task-data.model";

@Injectable()
export abstract class BaseTasksComponent<T> implements OnInit, OnDestroy {

  constructor(protected gridFormatterService: GridFormatterService, protected optgDashboardService: OPTGDashboardService) {
  }

  public gridOptions!: GridOptions;
  public entityName!: string;
  private readonly CONTAINS_GRID_FILTER_TYPE = 'contains';
  private readonly NOT_CONTAINS_GRID_FILTER_TYPE = 'notContains';
  private readonly EQUALS_GRID_FILTER_TYPE = 'equals';
  private readonly NOT_EQUAL_GRID_FILTER_TYPE = 'notEqual';
  private readonly STARTS_WITH_GRID_FILTER_TYPE = 'startsWith';
  private readonly ENDS_WITH_GRID_FILTER_TYPE = 'endsWith';
  private readonly IN_RANGE_GRID_FILTER_TYPE = 'inRange';
  private readonly LESS_THAN_GRID_FILTER_TYPE = 'lessThan';
  private readonly GREATER_THAN_GRID_FILTER_TYPE = 'greaterThan';

  protected readonly APP_SOURCE_COLUMN_ID = 'appSource';
  protected readonly POSTED_DATE_COLUMN_ID = 'postedDate';
  protected readonly SUBMITTED_DATE_COLUMN_ID = 'submittedDate';
  protected readonly DUE_DATE_COLUMN_ID = 'dueDate';
  protected readonly TASK_NAME_COLUMN_ID = 'taskName';
  protected readonly DESCRIPTION_COLUMN_ID = 'description';
  protected readonly URL_COLUMN_ID = 'url';
  protected readonly RESPONSIBILITY_COLUMN_ID = 'responsibility';

  protected readonly MY_TASKS_ENTITY_NAME = 'nexus.vw_myTasks';
  protected readonly TASKS_IN_PROGRESS_ENTITY_NAME = 'nexus.vw_tasksInProgress';

  protected readonly SORT_ORDER_ASCENDING = 'asc';

  private subscriptions = new Array<Subscription>();

  public ngOnInit(): void {
    this.gridFormatterService.defaultGridOptions = <GridOptions>{
      defaultColDef: this.gridFormatterService.defaultColDef,
      enableBrowserTooltips: true,
      pagination: false,
      paginationAutoPageSize: false,
      suppressHorizontalScroll: false,
      rowModelType: 'infinite',
      alwaysMultiSort: true,
      suppressCellFocus: false,
      masterDetail: false,
      rowSelection: 'single'
    };

    this.gridOptions = this.gridFormatterService.BuildDefaultGridOptionsWithColumns(this.buildColumns(), false);
    this.gridOptions.rowModelType = 'infinite';
    this.gridOptions.cacheBlockSize = 50;

    this.gridOptions.onGridReady = (params: any) => {
      this.onGridReady<T>(params);
      this.gridFormatterService.gridReadySizeToFit(params);
      this.gridOptions?.api?.hideOverlay();
    };
  }

  public onGridReady<T>(params: any) {
    const tasksDataSub = this.optgDashboardService.getFullEntityData(this.entityName).pipe(finalize(() => {
      this.subscriptions.push(tasksDataSub);
    })).subscribe((result: T[]) => {
      if (this.entityName == this.TASKS_IN_PROGRESS_ENTITY_NAME) {
        this.sortData(result, [{colId: this.SUBMITTED_DATE_COLUMN_ID, sort: this.SORT_ORDER_ASCENDING}]);
      } else {
        this.sortData(result, [{colId: this.DUE_DATE_COLUMN_ID, sort: this.SORT_ORDER_ASCENDING}]);
      }

      //Use for infinite rowModelType
      const datasource: IDatasource = {
        rowCount: undefined,
        getRows: (params: IGetRowsParams) => {
          let filteredData = Object.keys(params.filterModel).length > 0 ? this.filterData<T>(result, params.filterModel) : result;
          if (params.sortModel.length) {
            this.sortData(filteredData, params.sortModel);
          }
          const rowsInViewport = filteredData.slice(params.startRow, params.endRow);
          let lastRow = -1;
          if (filteredData.length <= params.endRow) {
            lastRow = filteredData.length;
          }
          params.successCallback(rowsInViewport, lastRow);
          this.gridFormatterService.resizeGridColumns(this.gridOptions?.api);
          console.log(params.sortModel);
        }
      };
      this.gridOptions?.api?.setDatasource(datasource);
    });
  }

  // Custom client-side filters needed for when infinite row model is used
  public filterData<T>(allData: T[], filterModel: any): T[] {
    let filteredData: T[] = [];

    for (let data of allData) {
      let hasNoMatch: boolean = false;
      for (let property of Object.keys(filterModel)) {
        const value = data[property as keyof T];
        let dateFromFilter = filterModel[property]?.filterType == 'date' ? new Date(filterModel[property]?.dateFrom) : null;
        let dateToFilter = filterModel[property]?.filterType == 'date' ? new Date(filterModel[property]?.dateTo) : null;
        let textFilter = filterModel[property]?.filterType == 'text' ? filterModel[property]?.filter?.toLowerCase() : null;
        let dateValue = value ? new Date(new Date(value as string).setHours(0, 0, 0, 0)) : null;
        let setValuesFilter = filterModel[property]?.filterType == 'set' ? filterModel[property]?.values.map((val: string) => val.toLowerCase()) : null;

        if (setValuesFilter && typeof(value) == 'string' && !setValuesFilter.some((x: string) => x.toLowerCase() == value?.toLowerCase())) {
          hasNoMatch = true;
        }
        if (filterModel[property]?.type == this.CONTAINS_GRID_FILTER_TYPE && typeof(value) == 'string' && textFilter && value?.toLowerCase()?.indexOf(textFilter) <= -1) {
          hasNoMatch = true;
        }
        if (filterModel[property]?.type == this.NOT_CONTAINS_GRID_FILTER_TYPE && typeof(value) == 'string' && textFilter && value?.toLowerCase()?.indexOf(textFilter) > -1) {
          hasNoMatch = true;
        }
        if (filterModel[property]?.type == this.STARTS_WITH_GRID_FILTER_TYPE && typeof(value) == 'string' && textFilter && !value?.toLowerCase()?.startsWith(textFilter)) {
          hasNoMatch = true;
        }
        if (filterModel[property]?.type == this.ENDS_WITH_GRID_FILTER_TYPE && typeof(value) == 'string' && textFilter && !value?.toLowerCase()?.endsWith(textFilter)) {
          hasNoMatch = true;
        }
        if (filterModel[property]?.type == this.EQUALS_GRID_FILTER_TYPE && typeof(value) == 'string' && textFilter && value?.toLowerCase() !== textFilter) {
          hasNoMatch = true;
        }
        if (filterModel[property]?.type == this.NOT_EQUAL_GRID_FILTER_TYPE && typeof(value) == 'string' && textFilter && value?.toLowerCase() === textFilter) {
          hasNoMatch = true;
        }
        if (filterModel[property]?.type == this.EQUALS_GRID_FILTER_TYPE && dateValue && dateFromFilter && dateValue.toLocaleDateString() != dateFromFilter.toLocaleDateString()) {
          hasNoMatch = true;
        }
        if (filterModel[property]?.type == this.NOT_EQUAL_GRID_FILTER_TYPE && dateValue && dateFromFilter && dateValue.toLocaleDateString() == dateFromFilter.toLocaleDateString()) {
          hasNoMatch = true;
        }
        if (filterModel[property]?.type == this.LESS_THAN_GRID_FILTER_TYPE && dateValue && dateFromFilter && dateValue >= dateFromFilter) {
          hasNoMatch = true;
        }
        if (filterModel[property]?.type == this.GREATER_THAN_GRID_FILTER_TYPE && dateValue && dateFromFilter && dateValue <= dateFromFilter) {
          hasNoMatch = true;
        }
        if (filterModel[property]?.type == this.IN_RANGE_GRID_FILTER_TYPE && dateValue && dateFromFilter && dateToFilter && (dateValue < dateFromFilter || dateValue > dateToFilter)) {
          hasNoMatch = true;
        }
      }
      if (!hasNoMatch) {
        filteredData.push(data);
      }
    }
    return filteredData;
  }

  public getDefaultDateFilterParams(): IDateFilterParams {
    let defaultDateFilterParams = this.gridFormatterService.defaultDateFilterParams;
    defaultDateFilterParams.maxNumConditions = 1;
    defaultDateFilterParams.filterOptions = (([
      'equals', 'notEqual', 'greaterThan','lessThan', 'inRange']) as any);
    return defaultDateFilterParams;
  }

  public getDefaultTextFilterParams(): ITextFilterParams {
    let defaulTextFilterParams = this.gridFormatterService.defaultTextFilterParams;
    defaulTextFilterParams.maxNumConditions = 1;
    defaulTextFilterParams.filterOptions = (([
      'equals', 'notEqual', 'contains','notContains', 'startsWith', 'endsWith']) as any);
    return defaulTextFilterParams;
  }

  public getDefaultNumberFilterParams(): INumberFilterParams {
    let defaultNumberFilterParams = this.gridFormatterService.defaultNumberFilterParams;
    defaultNumberFilterParams.maxNumConditions = 1;
    defaultNumberFilterParams.filterOptions = (([
      'equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange', 'contains',
      'notContains', 'startsWith', 'endsWith'
      ]) as any); //all options except blank/notblank which are not necessary for these number fields
    return defaultNumberFilterParams;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  protected abstract buildColumns(): Array<ColDef>;
  protected abstract sortData(data: any, sortModel: any): void;
}
