import { ICellRenderer } from "ag-grid-enterprise";
import { Component } from "@angular/core";
import { IImageCellRendererParams } from "../models/iimage-cell-renderer-params";
import { IconSpriteModule } from "ng-svg-icon-sprite";
import { FINANCE_AND_OPERATIONS_APP, ONBASE_APP, PPR_APP, SELECT_ALL_AG_GRID_FILTER_OPTION } from "../../../models/constants.model";

@Component({
  selector: 'app-source-image-renderer',
  templateUrl: 'app-source-image-renderer.component.html',
  styleUrls: ['./app-source-image-renderer.component.scss'],
  standalone: true,
  imports: [IconSpriteModule]
})
export class AppSourceImageRendererComponent implements ICellRenderer<IImageCellRendererParams> {

  public params!: any;
  public valueForTextOnly!: string;

  agInit(params: any): void {
    this.params = params;
    this.setParameters();
  }

  setParameters() {
    const appSourceValue: string = this.params?.data?.appSource ? this.params?.data.appSource : this.params?.value;
    if (appSourceValue === SELECT_ALL_AG_GRID_FILTER_OPTION) {
      this.valueForTextOnly = appSourceValue;
    } else if (appSourceValue) {
      this.setIconUrl(appSourceValue);
      this.params.imageTitle = this.params.imageTitle != '' && this.params.imageTitle != null ? this.params.imageTitle : '';
    }
  }

  private setIconUrl(appSourceValue: string): void {
    switch (appSourceValue?.toLowerCase()) {
      case ONBASE_APP:
        this.params.imageUrl = 'assets/sprites/sprite.svg#icon-onbase';
        this.params.imageTitle = 'OnBase';
        break;
      case PPR_APP:
        this.params.imageUrl = 'assets/sprites/sprite.svg#icon-PPR';
        this.params.imageTitle = 'Project Portfolio Review';
        break;
      case FINANCE_AND_OPERATIONS_APP:
        this.params.imageUrl = 'assets/sprites/sprite.svg#icon-finance-operations';
        this.params.imageTitle = 'Finance & Operations';
        break;
    }
  }

  refresh(params: IImageCellRendererParams): boolean {
    this.params = params;
    this.setParameters();
    return true;
  }
}
