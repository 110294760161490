import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoggingService } from './logging.service';

@Injectable({providedIn: 'root'})
export class AuthService {
  public authenticated: boolean = false;
  private infoRetrieved = false;
  public userInfo!: any;

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService
  ) {

  }

  public get(): Observable<any> {
    return this.http.get<any>('/api/auth');
  }

  public getUserInfo(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.userInfo || this.infoRetrieved) {
        this.authenticated = (this.userInfo != null && this.userInfo != undefined);
        resolve(this.userInfo);
      } else {
        this.http.get<any>('/api/auth/userInfo').subscribe((response: any) => {
          this.userInfo = response;
          this.infoRetrieved = true;
          if (this.userInfo) {
            this.authenticated = true;
          }
          resolve(this.userInfo);
        }, (error: any) => {
          reject(error);
        });
      }
    });
  }

  public getSlimUserInfo(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.userInfo || this.infoRetrieved) {
        this.authenticated = (this.userInfo != null && this.userInfo != undefined);
        resolve(this.userInfo);
      } else {
        this.http.get<any>('/api/auth/slimuserInfo').subscribe((response: any) => {
          this.userInfo = response;
          this.infoRetrieved = true;
          if (this.userInfo) {
            this.authenticated = true;
          }
          resolve(this.userInfo);
        }, (error: any) => {
          reject(error);
        });
      }
    });
  }

  public hasPermission(permission: string): boolean {
    if (!this.userInfo) {
      return false;
    }
    for (let i = 0; i < this.userInfo.permissions.length; i++) {
      if (this.userInfo.permissions[i] === permission) { return true; }
    }
    return false;
  }

  public logoutUser() {
    return this.http.get<any>('/api/auth/logout');
  }

}
