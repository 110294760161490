import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { NgxChartsModule } from '@swimlane/ngx-charts';

import { AuthService } from './services/auth.service';
import { LoggingService } from './services/logging.service';
import { OPTGDashboardService } from './services/optg-dashboard.service';
import { DragService } from "./services/drag.service";

import { provideAnimations } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { ToastrModule, ToastrService } from 'ngx-toastr';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxChartsModule,
    ToastrModule.forRoot()
  ],
  providers: [AuthService, LoggingService, OPTGDashboardService, DragService, ToastrService, provideAnimations()]
})
export class AppModule { }
