import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MyTaskData } from '../models/my-task-data.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomDataService {
  private userDirectoryDefaultData = [];
  constructor(private http: HttpClient) { }

  public getDirectoryDataForUser(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.userDirectoryDefaultData && this.userDirectoryDefaultData.length) {
        resolve(this.userDirectoryDefaultData);
      } else {
        this.http.get<any>('/api/customdata/getDirectoryDataForUser').subscribe((response: any) => {
          this.userDirectoryDefaultData = response;
          resolve(this.userDirectoryDefaultData);
        }, (error: any) => {
          reject(error);
        });
      }
    });
  }

  public getDirectoryDataForUserByName(userPrincipalName: string): Observable<any> {
    return this.http.get<any>(`/api/customdata/GetDirectoryDataForUserByName/${userPrincipalName}`);
  }

  public searchDirectoryData(searchString: string, searchType:string) : Observable<any> {
    return this.http.get<any>(`/api/customdata/searchDirectoryData/${searchString}/${searchType}`);
  }
}
