
<div class="fixed-container">
  <ul class="main-tab-container">
    <li [ngClass]="{'active' : selectedTab == tasksTabTypeEnum.MyTasks}">
      <button (click)="selectedTab=tasksTabTypeEnum.MyTasks">My Tasks</button>
    </li>

    <li [ngClass]="{'active' : selectedTab == tasksTabTypeEnum.TasksInProgress}">
      <button (click)="selectedTab=tasksTabTypeEnum.TasksInProgress">Tasks in Progress</button>
    </li>
  </ul>
</div>

<div class="selected-tab-container" *ngIf="selectedTab == tasksTabTypeEnum.MyTasks">
  <div class="overflow">
    <my-tasks></my-tasks>
  </div>
</div>

<div class="selected-tab-container" *ngIf="selectedTab == tasksTabTypeEnum.TasksInProgress">
  <div class="overflow">
    <tasks-in-progress></tasks-in-progress>
  </div>
</div>
