<header>
  <nav>
    <div class="nav-items">
      <div>
        <a href="https://my.jmt.com/" target="_blank">
          <!--<img class="my-jmt" src="../../assets/icons/nav/my-jmt-header-logo.png">-->
          <svg-icon-sprite [classes]="'my-jmt icon-white'" [src]="'../../assets/sprites/sprite.svg#nexus-logo-white'"
            [width]="'200px'" [height]="'44px'" [viewBox]="'0 0 169.17 36'"></svg-icon-sprite>
        </a>
      </div>
      <div class="nav-item dashboard">
        <span class="p-b--50">Dashboard</span>
      </div>
      <div class="nav-item intranet">
        <a href="https://my.jmt.com/" target="_blank"><span class="p-b--50">Intranet</span></a>
      </div>
      <div class="nav-item shortcuts" (click)="toggleShortcuts($event)">
        <button class="link-button header fnt-20 flex flex-align-baseline shortcuts-header">
          Shortcuts
          <svg-icon-sprite [classes]="'icon-white m-l--25 m-t--25'"
            [src]="'../../assets/sprites/sprite.svg#icon-chevron-down'" [width]="'18px'" [height]="'18px'"
            [viewBox]="'0 0 32 32'"></svg-icon-sprite>
        </button>
        <div *ngIf="shortcutsVisible" class="dropdown-content">
          <button class="close-dropdown-content" (click)="toggleMenuHamburger($event)">&times; </button>
          <ul class="shortcuts-list reset">
            <li class="no-icon">
              <button class="shortcuts-dashboard-button link-button fnt-16" (click)="toggleShortcuts($event)">
                Dashboard
              </button>
            </li>
            <li class="no-icon">
              <a href="https://my.jmt.com" target="_blank">
                <div>Intranet</div>
              </a>
            </li>
            <li class="shortcuts-divider"><hr></li>
            <li>
              <a href="https://appcenter.jmt.com/apps/contracts/dashboard" target="_blank">
                <svg-icon-sprite [src]="'../../assets/sprites/sprite.svg#icon-app-center'" [width]="'32px'"
                                 [viewBox]="'0 0 31 32'"></svg-icon-sprite>
                <div>App Center</div>
              </a>
            </li>
            <li>
              <a href="https://jmt365.crm.dynamics.com/" target="_blank">
                <svg-icon-sprite [src]="'../../assets/sprites/sprite.svg#icon-crm'" [width]="'32px'"
                                 [viewBox]="'0 0 32 32'"></svg-icon-sprite>
                <div>CRM</div>
              </a>
            </li>
            <li>
              <a href="https://jmt.operations.dynamics.com/" target="_blank">
                <svg-icon-sprite [src]="'../../assets/sprites/sprite.svg#icon-finance-operations'" [width]="'32px'"
                                 [viewBox]="'0 0 32 32'"></svg-icon-sprite>
                <div>Finance and Operations</div>
              </a>
            </li>
            <li>
              <a href="https://global.hgncloud.com/jmt/welcome.jsp" target="_blank">
                <svg-icon-sprite [src]="'../../assets/sprites/sprite.svg#icon-halogen'" [width]="'32px'"
                                 [viewBox]="'0 0 32 32'"></svg-icon-sprite>
                <div>Halogen</div>
              </a>
            </li>
            <li>
              <a href="https://app.powerbi.com/groups/me/apps/35a5aec1-bb1e-4c2d-9018-c3072bbe59a1/reports/50486645-8245-485c-b68a-370b466a2dbd/ReportSection71b16f559a681d1e070c?ctid=eb5bb4f9-ca67-467a-85b5-42d23ee13edb&experience=power-bi"
                 target="_blank">
                <svg-icon-sprite [src]="'../../assets/sprites/sprite.svg#icon-power-bi'" [width]="'32px'"
                                 [viewBox]="'0 0 32 32'"></svg-icon-sprite>
                <div>Power BI</div>
              </a>
            </li>
            <li>
              <a href="https://ppr.jmt.com/projects/list" target="_blank">
                <svg-icon-sprite [src]="'../../assets/sprites/sprite.svg#icon-PPR'" [width]="'32px'"
                                 [viewBox]="'0 0 32 32'"></svg-icon-sprite>
                <div>Project Portfolio Review</div>
              </a>
            </li>
            <li>
              <a href="https://recordscenter.jmt.com/" target="_blank">
                <svg-icon-sprite [src]="'../../assets/sprites/sprite.svg#icon-onbase'" [width]="'32px'"
                                 [viewBox]="'0 0 32 32'"></svg-icon-sprite>
                <div>OnBase</div>
              </a>
            </li>
            <li>
              <a href="https://ultipro.jmt.com/" target="_blank">
                <svg-icon-sprite [src]="'../../assets/sprites/sprite.svg#icon-UKG'" [width]="'32px'"
                                 [viewBox]="'0 0 32 32'"></svg-icon-sprite>
                <div>UKG Pro</div>
              </a>
            </li>
            <li>
              <a href="https://my.jmt.com/documents/sppreview/1a65334e-f601-4f35-839d-271661b702f7" target="_blank">
                <div class="link-only">Fee Derivation Template</div>
              </a>
            </li>
            <li>
              <a href="https://my.jmt.com/sites/policies-procedures/SitePageModern/26585/policies-procedures-library"
                 target="_blank">
                <div class="link-only">Policies & Procedures</div>
              </a>
            </li>
            <li>
              <a href="https://my.jmt.com/sites/project-resources/SitePageModern/19763/project-lifecycle-research"
                 target="_blank">
                <div class="link-only">Project Lifecycle</div>
              </a>
            </li>
            <li>
              <a href="https://jmt365.sharepoint.com/sites/myJMT/Shared Documents/Forms/AllItems.aspx?id=%2fsites%2fmyJMT%2fShared Documents%2fMarketing%2fJMT Written Style Guide%2Epdf&parent=%2fsites%2fmyJMT%2fShared Documents%2fMarketing"
                 target="_blank">
                <div class="link-only">Written Style Guide</div>
              </a>
            </li>
            <li>
              <a href="https://goals.cloud.microsoft/" target="_blank">
                <div class="link-only">Viva Goals</div>
              </a>
            </li>
          </ul>
        </div>

      </div>
    </div>
    <div class="user-hamburger-container">
      <a *ngIf="!userInfo" href="/api/auth/login" class="user-info"><span>Login</span></a>
      <a *ngIf="userInfo" href="/api/auth/login" class="user-settings">
        <svg-icon-sprite [classes]="'icon-white'" [src]="'../../assets/sprites/sprite.svg#icon-user-settings'"
          [width]="'32px'" [viewBox]="'0 0 32 32'"></svg-icon-sprite>
      </a>
      <button *ngIf="userInfo" class="hamburger-button hamburger" (click)="toggleMenuHamburger($event)">
        <svg-icon-sprite [src]="'../../assets/sprites/sprite.svg#icon-hamburger'" [width]="'32px'"
          [viewBox]="'0 0 32 32'"></svg-icon-sprite>
      </button>
    </div>

  </nav>

  <div class="attic-container">
    <button class="greeting-container" (click)="toggleAttic()">
      <span>
        <span *ngIf="userGreeting !== ''" class="greeting">{{userGreeting}}</span>
        <span *ngIf="userInfo" class="chevron" [ngClass]="{ 'chevron-up' : atticShowing }"><svg-icon-sprite
            [src]="'../../assets/sprites/sprite.svg#icon-chevron-down'" [width]="'20px'"
            [viewBox]="'0 0 20 20'"></svg-icon-sprite></span>
      </span>
    </button>
    <section class="attic-background">
      <attic *ngIf="atticShowing"></attic>
    </section>
  </div>
</header>
