import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { AppModule } from '../../app.module';
import { MyTasksComponent } from './my-tasks.component';
import { TasksInProgressComponent } from './tasks-in-progress.component';
import { TasksTabType } from '../../../app/enums/tasks-tab-type.enum';

@Component({
  standalone: true,
  selector: 'tasks-tab-container',
  templateUrl: './tasks-tab-container.component.html',
  styleUrl: './tasks-tab-container.component.scss',
  imports: [AppModule, CommonModule, FormsModule, MyTasksComponent, TasksInProgressComponent],
  providers: [DatePipe, CurrencyPipe]
})
export class TasksTabContainerComponent implements OnInit, OnDestroy {
  public selectedTab: TasksTabType = TasksTabType.MyTasks;

  public tasksTabTypeEnum = TasksTabType;

  constructor() {

  }
  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {

  }
}
