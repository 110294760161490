import { ICellRenderer } from "ag-grid-enterprise";
import { Component } from "@angular/core";
import { IconSpriteModule } from "ng-svg-icon-sprite";
import { IImageCellRendererParams } from "../../models/iimage-cell-renderer-params";

@Component({
  selector: 'project-flag-renderer',
  templateUrl: 'project-flag-renderer.component.html',
  standalone: true,
  imports: [IconSpriteModule]
})
export class ProjectFlagRenderer implements ICellRenderer<IImageCellRendererParams> {
  public hasRedFlag: boolean = false;
  public hasYellowFlag: boolean = false;

  agInit(params: IImageCellRendererParams): void {
    if (params.data) {
      this.hasRedFlag = params.data.redFlagCount > 0;
      this.hasYellowFlag = params.data.yellowFlagCount > 0;
    }
  }

  refresh(params: any): boolean {
    return true;
  }
}
