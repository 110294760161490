/// <reference types="@angular/localize" />

import { enableProdMode, provideExperimentalZonelessChangeDetection} from '@angular/core';

import { environment } from './environments/environment';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/components/app.component';
import { provideHttpClient } from '@angular/common/http';
import { provideRouter } from '@angular/router';
import { routes } from './app-routes';
import { LicenseManager } from 'ag-grid-enterprise';

LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-070901}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Johnson,_Mirmiran_&_Thompson,_Inc.}_is_granted_a_{Multiple_Applications}_Developer_License_for_{7}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{8}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{5_March_2026}____[v3]_[01]_MTc3MjY2ODgwMDAwMA==488315c318007b6be18427e462eccfe1");

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideExperimentalZonelessChangeDetection(),
    provideHttpClient(),
    provideRouter(routes),
  ]
})
