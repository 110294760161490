import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';

@Component({
  selector: 'employee-list-item',
  standalone: true,
  imports: [CommonModule, IconSpriteModule],
  templateUrl: './employee-list-item.component.html',
  styleUrl: './employee-list-item.component.scss'
})
export class EmployeeListItemComponent {
  @Input() employee: any;
  @Output() selectedEmployee = new EventEmitter<any>();

  selectEmployee() {
    this.selectedEmployee.emit(this);
  }

  public get spriteSrc(): string {
    if (this.employee.isSelected) {
      return "../../assets/sprites/sprite.svg#icon-user-blue";
    } else {
      return "../../assets/sprites/sprite.svg#icon-user-grey";
    }
  }

}
