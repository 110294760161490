import { Component, OnInit, ChangeDetectorRef, input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CustomDataService } from '../../services/custom-data.service';
import { AppModule } from '../../app.module';
import { LoggingService } from '../../services/logging.service';
import { ToastrService } from 'ngx-toastr';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { EmployeeListItemComponent } from './employee-list-item/employee-list-item.component';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, map, Observable, of, OperatorFunction, Subscription, switchMap } from 'rxjs';
import { EmployeeDetailsComponent } from './employee-details/employee-details.component';
import { OPTGDashboardService } from '../../services/optg-dashboard.service';

@Component({
  standalone: true,
  selector: 'employee-directory',
  templateUrl: './employee-directory.component.html',
  styleUrl: './employee-directory.component.scss',
  imports: [AppModule, CommonModule, FormsModule,
    IconSpriteModule, EmployeeListItemComponent,
    EmployeeDetailsComponent,
    NgbTypeaheadModule],
})
export class EmployeeDirectoryComponent implements OnInit {
  public employees: any[] = [];
  public employeesRef: any[] = [];
  public searchText: string = '';
  public searchType: string = 'name';
  public supervisor: any = null;
  public employeesIncludingSelf: any[] = [];
  public directReports: any[] = [];
  public directReportsEmails: string = "";
  public selectedEmployee: any;
  public selectedEmployeeRef: any;
  public selectedOffice: any;
  public officeLeader: any;
  public selectedPractice: any;
  public practiceLeader: any;
  public defaultEmployee: any;
  private resultRef: any[] = [];
  public offices: any[] = [];
  public officeFilter: string = '';
  public officeFilterSelected: boolean = false;
  public practices: any[] = [];
  public practiceFilter: string = '';
  public practiceFilterSelected: boolean = false;
  public filterVisible: boolean = false;
  private subscriptions: Subscription[] = [];

  displayHeader = input<boolean>(true);

  constructor(
    private customDataService: CustomDataService,
    private cd: ChangeDetectorRef,
    private loggingService: LoggingService,
    private toastr: ToastrService,
    private dashboardService: OPTGDashboardService
  ) {

  }

  ngOnInit(): void {
    this.getDefaultData();
  }

  private getDefaultData() {
    this.customDataService.getDirectoryDataForUser().then(res => {
      this.resultRef = res;
      this.formatDirectoryData(res);
      this.defaultEmployee = this.selectedEmployee;
      this.cd.detectChanges();
      window.setTimeout(() => {this.toastr.info("Info retrieved"); }, 500);

    }, err => {
      this.loggingService.logError("Error retrieving Directory data").subscribe();
      window.setTimeout(() => { this.toastr.error("Error retrieving directory data."); }, 500);
    });
  }

  private getEmployeeData(userPrincipalName: string, detailsOnly: boolean = false) {
    const subscription = this.customDataService.getDirectoryDataForUserByName(userPrincipalName)
      .pipe(
          finalize(() => {
            this.subscriptions.push(subscription);
          }),
          catchError(() => {
            window.setTimeout(() => { this.toastr.error("Error retrieving user directory data by name.") }, 500);
            if (!detailsOnly) {
              this.resultRef = [];
              this.formatDirectoryData([]);
            }
            return of([]);
          }))
      .subscribe(res => {
        if (detailsOnly) {
          this.getEmployeeDetails(res);
        } else {
          this.resultRef = res;
          this.formatDirectoryData(res);
        }

        this.cd.detectChanges();
        window.setTimeout(() => { this.toastr.info("User directory data by name retrieved") }, 500)
      }
    );
  }

  private searchDirectoryData(searchText: string, searchType: string)  {
    const subscription = this.customDataService.searchDirectoryData(searchText, searchType)
      .pipe(
          finalize(() => {
            this.subscriptions.push(subscription);
          }),
          catchError(() => {
            window.setTimeout(() => { this.toastr.error("Error retrieving user directory data by office/practice.") }, 500);
            this.formatDirectoryData([]);
            return of([]);
          }))
      .subscribe(res => {
        this.employeesRef = res;
        this.employees = res;
        if (searchType === "office") {
          this.officeLeader = this.employees.find(x => x.isOfficeLeader);
          if (this.officeLeader) {
            this.getEmployeeData(this.officeLeader.userPrincipalName, true);
          } else {
            this.selectedEmployee = null;
          }
          if (this.practiceFilterSelected) {
            this.employees = this.employeesRef.filter(x => x.department === this.practiceFilter);
          }
        }
        if (searchType === "practice") {
          this.practiceLeader = this.employees.find(x => x.isPracticeLeader);
          if (this.officeFilterSelected) {
            this.employees = this.employeesRef.filter(x => x.office === this.officeFilter);
          }
        }
        this.cd.detectChanges();
        window.setTimeout(() => { this.toastr.info("User directory data by office/preactice retrieved") }, 500)
      }
    );
  }

  public searchTypeChanged(event: any) {
    this.cd.detectChanges();
    this.searchType = event.target.value;
    switch (this.searchType) {
      case "name":
        if (!this.selectedEmployee) {
          if (this.selectedEmployeeRef) {
            this.selectedEmployee = this.selectedEmployeeRef;
          } else {
            this.selectedEmployee = this.defaultEmployee;
          }
        }
        this.getEmployeeData(this.selectedEmployee.userPrincipalName);
        break;
      case "office":
        this.getOffices();
        this.getPractices();
        if (!this.selectedOffice) {
          this.selectedOffice = this.defaultEmployee.office;
        }
        this.searchDirectoryData(this.selectedOffice, this.searchType);
        break;
      case "practice":
        this.getOffices();
        this.getPractices();
        if (!this.selectedPractice) {
          this.selectedPractice = this.defaultEmployee.department;
        }
        this.searchDirectoryData(this.selectedPractice, this.searchType);
        break;
      default:
        break;
    }
  }

  changeSelectedEmployee(event: any) {
    if (event.employee) {
      this.selectedEmployeeRef = event.employee;
      this.selectedEmployee = event.employee;

      if (this.searchType === "name") {
        this.formatDirectoryData(this.resultRef, true);
        this.getEmployeeData(this.selectedEmployee.userPrincipalName);

        let match = null;
        if (this.supervisor === this.selectedEmployee) {
          match = this.supervisor;
          match.isSelected = true;
          return;
        }
        match = this.employeesIncludingSelf.find(x => x.employeeNumber === this.selectedEmployee.employeeNumber);
        if (match) {
          match.isSelected = true;
          return;
        }
        match = this.directReports.find(x => x.employeeNumber === this.selectedEmployee.employeeNumber);
        if (match) {
          match.isSelected = true;
        }
      } else {
        const selected = this.employees.find(x => x.isSelected);
        if (selected) {
          selected.isSelected = false;
        }
        this.getEmployeeData(this.selectedEmployee.userPrincipalName, true);
        this.selectedEmployee.isSelected = true;
      }
      this.cd.detectChanges();
    }
  }

  private formatDirectoryData(res: any, resetSelection: boolean = false) {

    this.supervisor = null;
    this.employeesIncludingSelf = [];
    this.directReports = [];

    if (!res) {
      return;
    }

    this.supervisor = res.supervisor;
    if (this.supervisor) {
      this.supervisor.isSelected = false;
    }

    this.employeesIncludingSelf = res.employees;

    if (resetSelection) {
      this.employeesIncludingSelf.forEach(x => {
        x.isSelected = false;
      });
    } else {
      this.employeesIncludingSelf[0].isSelected = this.employeesIncludingSelf[0].isSelf === 1;
      this.selectedEmployee = this.employeesIncludingSelf[0];
    }

    let directReports = [];
    directReports = this.employeesIncludingSelf.filter(x => x.supervisorEmployeeNumber === this.employeesIncludingSelf[0].employeeNumber);
    if (directReports.length > 0) {
      this.employeesIncludingSelf = [this.employeesIncludingSelf[0]];
      this.directReports = directReports;
      this.directReportsEmails = directReports.map(x => {return x.emailAddress}).join(";");
      if (resetSelection) {
        this.directReports.forEach(x => {
          x.isSelected = false;
        });
      }
    } else {
      this.directReportsEmails = "";
    }
  }

  private getEmployeeDetails(res: any) {
    if (!this.selectedEmployee || !this.selectedEmployee.employeeNumber) {
      return;
    }
    const directReports = (res.employees as any[]).filter(x => x.supervisorEmployeeNumber === this.selectedEmployee.employeeNumber);
    this.directReportsEmails = directReports.map(x => { return x.emailAddress }).join(",");
  }

  private getOffices() {
    if (this.offices.length > 0) return;
    this.dashboardService.getFullEntityData("employee.office").subscribe(res => {
      this.offices = res.sort((a: any, b: any) => a.name.localeCompare(b.name));
    }, err => {
      this.loggingService.logError("Error getting data for " + "employee.office");
    });
  }

  private getPractices() {
    if (this.practices.length > 0) return;
    this.dashboardService.getFullEntityData("employee.department").subscribe(res => {
      this.practices = res.sort((a: any, b: any) => a.name.localeCompare(b.name));
      this.practices = this.practices.filter(x => x.active);
    }, err => {
      this.loggingService.logError("Error getting data for " + "employee.practice");
    });
  }

  public toggleFilter() {
    this.filterVisible = !this.filterVisible;
    this.cd.detectChanges();
  }

  public onOfficeSelect(event: any) {
    this.officeFilter = event.target.value;
    this.officeFilterSelected = this.officeFilter !== "";
    if (this.officeFilterSelected) {
      this.employees = this.employeesRef.filter(x => x.office === this.officeFilter);
    } else {
      this.employees = this.employeesRef;
    }
    this.cd.detectChanges();
  }

  public onPracticeSelect(event: any) {
    this.practiceFilter = event.target.value;
    this.practiceFilterSelected = this.practiceFilter !== "";
    if (this.practiceFilterSelected) {
      this.employees = this.employeesRef.filter(x => x.department === this.practiceFilter);
    } else {
      this.employees = this.employeesRef;
    }
    this.cd.detectChanges();
  }

  public loadPractice(event: any) {
    this.searchType = "practice";
    this.getOffices();
    this.getPractices();
    this.selectedPractice = event._selectedEmployee.department;
    this.selectedEmployee = null;
    this.officeFilter = '';
    this.officeFilterSelected = false;
    this.searchDirectoryData(this.selectedPractice, this.searchType);
    this.cd.detectChanges();
  }

  public loadOffice(event: any) {
    this.searchType = "office";
    this.getOffices();
    this.getPractices();
    this.selectedOffice = event._selectedEmployee.office;
    this.selectedEmployee = null;
    this.practiceFilter = '';
    this.practiceFilterSelected = false;
    this.searchDirectoryData(this.selectedOffice, this.searchType);
    this.cd.detectChanges();
  }

  // Type-ahead related
  employeeFormatter = (result: any) => result.displayName;
  searchEmployee: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(300),
			distinctUntilChanged(),
      filter((term) => term.length >= 2),
			switchMap((term) =>
				this.customDataService.searchDirectoryData(term, this.searchType).pipe(
					catchError(() => {
						return of([]);
					})
				)
			)
		);
  onSelectEmployee(event: any): void {
    const employee = event.item;
    this.getEmployeeData(employee.userPrincipalName);
  }

  officeFormatter = (result: any) => result.name;
  searchOffice: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			map((term) =>
				term === '' || term.length < 2 ? [] : this.offices.filter((v) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10),
			),
		);
  onSelectOffice(event: any): void {
    this.practiceFilter = '';
    this.practiceFilterSelected = false;
    this.searchDirectoryData(event.item.name, this.searchType);
    this.selectedOffice = event.item.name;
  }

  practiceFormatter = (result: any) => result.name;
  searchPractice: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			map((term) =>
				term === '' || term.length < 2 ? [] : this.practices.filter((v) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10),
			),
		);
  onSelectPractice(event: any): void {
    this.officeFilter = '';
    this.officeFilterSelected = false;
    this.searchDirectoryData(event.item.name, this.searchType);
    this.selectedPractice = event.item.name;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

}
