<div *ngIf="_selectedOffice" class="office-details">
    <div class="office-info-item">Address Line 1 Placeholder</div>
    <div class="office-info-item">Address Line 2 Placeholder</div>
    <div class="office-info-item">City State Zip</div>
    <div class="office-info-item">Phone</div>

    <div *ngIf="_officeLeader" class="office-leader-details">
        <div class="employee-photo"><img src="https://jmt365.sharepoint.com/_layouts/15/userphoto.aspx?size=L&AccountName={{_officeLeader?.userPrincipalName}}" /></div>
        <div>
            <div class="employee-details-name">{{_officeLeader?.displayName}}</div>
            <div>{{_officeLeader?.jobCodeTitle}}</div>
        </div>
        <ul class="reset">
            <li *ngIf="_officeLeader?.homePhone && !_officeLeader.homePhoneIsPrivate" class="p-t--50 p-b--15">
                <a href="tel:{{_officeLeader?.homePhone}}" class="flex align-items-center">
                    <svg-icon-sprite [src]="'../../assets/sprites/sprite.svg#icon-mobile'" [width]="'16px'"
                        [viewBox]="'0 0 31 32'"></svg-icon-sprite>
                    <div class="p-l--25">Mobile: {{_officeLeader?.homePhone}}</div>
                </a>
            </li>
            <li *ngIf="_officeLeader?.workPhoneNumber" class="p-t--15 p-b--15">
                <a href="tel:{{_officeLeader?.workPhoneNumber}}" class="flex align-items-center">
                    <svg-icon-sprite [src]="'../../assets/sprites/sprite.svg#icon-phone'" [width]="'16px'"
                        [viewBox]="'0 0 31 32'"></svg-icon-sprite>
                    <div class="p-l--25">Office: {{_officeLeader?.workPhoneNumber}}</div>
                </a>
            </li>
            <li class="p-t--15 p-b--15">
                <a href="mailto:{{_officeLeader?.emailAddress}}" class="flex align-items-center">
                    <svg-icon-sprite [src]="'../../assets/sprites/sprite.svg#icon-email'" [width]="'16px'"
                        [viewBox]="'0 0 31 32'"></svg-icon-sprite>
                    <div class="p-l--25">{{_officeLeader?.emailAddress}}</div>
                </a>
            </li>
            <li class="p-t--15 p-b--15">
                <a href="https://teams.microsoft.com/l/chat/0/0?users={{_officeLeader?.emailAddress}}" class="flex align-items-center" target="_blank">
                    <svg-icon-sprite [src]="'../../assets/sprites/sprite.svg#icon-teams'" [width]="'16px'"
                        [viewBox]="'0 0 31 32'"></svg-icon-sprite>
                    <div class="p-l--25">Message in Teams</div>
                </a>
            </li>
        </ul>
    </div>
</div>
<div *ngIf="_selectedEmployee" class="employee-details">
    <div class="employee-photo"><img src="https://jmt365.sharepoint.com/_layouts/15/userphoto.aspx?size=L&AccountName={{_selectedEmployee?.userPrincipalName}}" /></div>
    <div>
        <div class="employee-details-name">{{_selectedEmployee?.displayName}}</div>
        <div>{{_selectedEmployee?.jobCodeTitle}}</div>
    </div>
    <ul class="reset">
        <li class="p-t--50 p-b--15">
            <button class="link-button fnt-12 flex align-items-center" (click)="selectDepartment()">
                <svg-icon-sprite [src]="'../../assets/sprites/sprite.svg#icon-practice'" [width]="'16px'"
                    [viewBox]="'0 0 31 32'"></svg-icon-sprite>
                <div class="p-l--25">{{_selectedEmployee?.department}}</div>
            </button>
        </li>
        <li *ngIf="_selectedEmployee?.homePhone && !_selectedEmployee.homePhoneIsPrivate" class="p-t--15 p-b--15">
            <a href="tel:{{_selectedEmployee?.homePhone}}" class="flex align-items-center">
                <svg-icon-sprite [src]="'../../assets/sprites/sprite.svg#icon-mobile'" [width]="'16px'"
                    [viewBox]="'0 0 31 32'"></svg-icon-sprite>
                <div class="p-l--25">Mobile: {{_selectedEmployee?.homePhone}}</div>
            </a>
        </li>
        <li *ngIf="_selectedEmployee?.workPhoneNumber" class="p-t--15 p-b--15">
            <a href="tel:{{_selectedEmployee?.workPhoneNumber}}" class="flex align-items-center">
                <svg-icon-sprite [src]="'../../assets/sprites/sprite.svg#icon-phone'" [width]="'16px'"
                    [viewBox]="'0 0 31 32'"></svg-icon-sprite>
                <div class="p-l--25">Office: {{_selectedEmployee?.workPhoneNumber}}</div>
            </a>
        </li>
        <li class="p-t--15 p-b--15">
            <a href="mailto:{{_selectedEmployee?.emailAddress}}" class="flex align-items-center">
                <svg-icon-sprite [src]="'../../assets/sprites/sprite.svg#icon-email'" [width]="'16px'"
                    [viewBox]="'0 0 31 32'"></svg-icon-sprite>
                <div class="p-l--25">{{_selectedEmployee?.emailAddress}}</div>
            </a>
        </li>
        <li class="p-t--15 p-b--15">
            <a href="https://teams.microsoft.com/l/chat/0/0?users={{_selectedEmployee?.emailAddress}}" class="flex align-items-center" target="_blank">
                <svg-icon-sprite [src]="'../../assets/sprites/sprite.svg#icon-teams'" [width]="'16px'"
                    [viewBox]="'0 0 31 32'"></svg-icon-sprite>
                <div class="p-l--25">Message in Teams</div>
            </a>
        </li>
        <li class="p-t--25 p-b--25" *ngIf="directReportsEmails.length > 0">
            <a href="mailto:{{directReportsEmails}}" class="flex align-items-center">
                <svg-icon-sprite [src]="'../../assets/sprites/sprite.svg#icon-email-group'" [width]="'16px'"
                    [viewBox]="'0 0 31 32'"></svg-icon-sprite>
                <div class="p-l--25">Email Group</div>
            </a>
        </li>
        <li class="p-t--15 p-b--15">
            <button class="link-button fnt-12 flex align-items-center" (click)="selectOffice()">
                <svg-icon-sprite [src]="'../../assets/sprites/sprite.svg#icon-office'" [width]="'16px'"
                    [viewBox]="'0 0 31 32'"></svg-icon-sprite>
                <div class="p-l--25">{{_selectedEmployee?.office}}</div>
            </button>
        </li>
    </ul>
</div>
<div *ngIf="_selectedPractice" class="practice-details">
    <div class="practice-info-item"></div>
    <div *ngIf="_practiceLeader" class="office-leader-details">
        <div class="employee-photo"><img src="https://jmt365.sharepoint.com/_layouts/15/userphoto.aspx?size=L&AccountName={{_practiceLeader?.userPrincipalName}}" /></div>
        <div>
            <div class="employee-details-name">{{_practiceLeader?.displayName}}</div>
            <div>{{_practiceLeader?.jobCodeTitle}}</div>
        </div>
        <ul class="reset">
            <li *ngIf="_practiceLeader?.homePhone && !_practiceLeader.homePhoneIsPrivate" class="p-t--50 p-b--15">
                <a href="tel:{{_practiceLeader?.homePhone}}" class="flex align-items-center">
                    <svg-icon-sprite [src]="'../../assets/sprites/sprite.svg#icon-mobile'" [width]="'16px'"
                        [viewBox]="'0 0 31 32'"></svg-icon-sprite>
                    <div class="p-l--25">Mobile: {{_practiceLeader?.homePhone}}</div>
                </a>
            </li>
            <li *ngIf="_practiceLeader?.workPhoneNumber" class="p-t--15 p-b--15">
                <a href="tel:{{_practiceLeader?.workPhoneNumber}}" class="flex align-items-center">
                    <svg-icon-sprite [src]="'../../assets/sprites/sprite.svg#icon-phone'" [width]="'16px'"
                        [viewBox]="'0 0 31 32'"></svg-icon-sprite>
                    <div class="p-l--25">Office: {{_practiceLeader?.workPhoneNumber}}</div>
                </a>
            </li>
            <li class="p-t--15 p-b--15">
                <a href="mailto:{{_practiceLeader?.emailAddress}}" class="flex align-items-center">
                    <svg-icon-sprite [src]="'../../assets/sprites/sprite.svg#icon-email'" [width]="'16px'"
                        [viewBox]="'0 0 31 32'"></svg-icon-sprite>
                    <div class="p-l--25">{{_practiceLeader?.emailAddress}}</div>
                </a>
            </li>
            <li class="p-t--15 p-b--15">
                <a href="https://teams.microsoft.com/l/chat/0/0?users={{_practiceLeader?.emailAddress}}" class="flex align-items-center" target="_blank">
                    <svg-icon-sprite [src]="'../../assets/sprites/sprite.svg#icon-teams'" [width]="'16px'"
                        [viewBox]="'0 0 31 32'"></svg-icon-sprite>
                    <div class="p-l--25">Message in Teams</div>
                </a>
            </li>
        </ul>
    </div>
</div>