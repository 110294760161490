import { Component, OnInit, input, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule, CurrencyPipe, DatePipe, formatDate } from '@angular/common';
import { AppModule } from '../../app.module';
import { ColDef } from 'ag-grid-community';
import { AgGridModule } from 'ag-grid-angular';
import { GridFormatterService } from '@jmttg/ag-grid-library';
import { AppSourceImageRendererComponent } from '../ag-grid/renderers/app-source-image-renderer.component';
import { MyTaskData } from '../../models/my-task-data.model';
import { DATE_FORMAT_STRING, DATE_LOCALE_STRING, FINANCE_AND_OPERATIONS_APP, ONBASE_APP, PPR_APP } from '../../../app/models/constants.model';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { ArrowImageLinkRendererComponent } from '../ag-grid/renderers/arrow-image-link-renderer.component';
import { IImageCellRendererParams } from '../ag-grid/models/iimage-cell-renderer-params';
import { OPTGDashboardService } from '../../../app/services/optg-dashboard.service';
import { BaseTasksComponent } from './base-tasks.component';
import { DisplayLargeStringModalRenderer } from '../ag-grid/renderers/display-large-string-modal/display-large-string-modal-renderer.component';

@Component({
  standalone: true,
  selector: 'my-tasks',
  templateUrl: './my-tasks.component.html',
  styleUrl: './my-tasks.component.scss',
  imports: [AgGridModule, AppModule, CommonModule, FormsModule, IconSpriteModule],
  providers: [GridFormatterService, DatePipe, CurrencyPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyTasksComponent extends BaseTasksComponent<MyTaskData> implements OnInit, OnDestroy {
  displayHeader = input<boolean>(true);

  constructor(protected optgDashboardService: OPTGDashboardService, protected gridFormatterService: GridFormatterService) {
    super(gridFormatterService, optgDashboardService);
    this.entityName = this.MY_TASKS_ENTITY_NAME;
  }

  protected buildColumns(): Array<ColDef> {
    const defaultDateFilterParams = this.getDefaultDateFilterParams();
    const defaultTextFilterParams = this.getDefaultTextFilterParams();

    let colDefs: Array<ColDef> = [];

    colDefs.push({
      colId: this.APP_SOURCE_COLUMN_ID,
      headerName: "",
      headerTooltip: "Application",
      field: 'appSource',
      filter: 'agSetColumnFilter',
      filterParams:  {
        //NOTE: Values have to be used for agSetColumnFilter when using infinite or server row model
        values: [FINANCE_AND_OPERATIONS_APP, PPR_APP, ONBASE_APP],
        cellRenderer: AppSourceImageRendererComponent
      },
      flex: 0.25,
      minWidth: 75,
      maxWidth: 75,
      headerClass:'wrap-header-text',
      menuTabs: ['filterMenuTab'],
      cellRenderer: AppSourceImageRendererComponent
    } as ColDef);

    colDefs.push({
      colId: this.POSTED_DATE_COLUMN_ID,
      headerName: "Date Posted",
      headerTooltip: "Posted Date",
      field: "postedDate",
      flex: 1,
      minWidth: 130,
      maxWidth: 140,
      menuTabs: ['filterMenuTab'],
      filter: "agDateColumnFilter",
      filterParams: defaultDateFilterParams,
      valueFormatter: (params) => {
        if (params.value) {
          //Converts date to user's local timezone from UTC
          return formatDate(`${params.value}Z`, DATE_FORMAT_STRING, DATE_LOCALE_STRING);
        } else {
          return "";
        }
      }
    } as ColDef);

    colDefs.push({
      colId: this.DUE_DATE_COLUMN_ID,
      headerName: "Due",
      headerTooltip: "Due Date",
      field: "dueDate",
      flex: 1,
      minWidth: 110,
      maxWidth: 110,
      menuTabs: ['filterMenuTab'],
      filter: "agDateColumnFilter",
      filterParams: defaultDateFilterParams,
      cellStyle: params => {
        if (params.value && new Date(new Date().toDateString()) > new Date(new Date(params.value + 'Z').toDateString())) {
          return {color: 'red'};
        }
        return null;
      },
      valueFormatter: (params) => {
        if (params.value) {
          //Converts date to user's local timezone from UTC
          return formatDate(`${params.value}Z`, DATE_FORMAT_STRING, DATE_LOCALE_STRING);
        } else {
          return "";
        }
      }
    } as ColDef);

    colDefs.push({
      colId: this.TASK_NAME_COLUMN_ID,
      headerName: "Task",
      headerTooltip: "Task Name",
      field: "taskName",
      flex: 2,
      minWidth: 220,
      maxWidth: 350,
      menuTabs: ['filterMenuTab'],
      filter: "agTextColumnFilter",
      filterParams: defaultTextFilterParams,
    } as ColDef);

    colDefs.push({
      colId: this.DESCRIPTION_COLUMN_ID,
      headerName: "Description",
      headerTooltip: "Description of the task",
      field: "description",
      flex: 3,
      minWidth: 350,
      maxWidth: 1250,
      wrapText: true,
      autoHeight: true,
      menuTabs: ['filterMenuTab'],
      filter: "agTextColumnFilter",
      filterParams: defaultTextFilterParams,
      cellRenderer: DisplayLargeStringModalRenderer
    } as ColDef);

    colDefs.push({
      colId: this.URL_COLUMN_ID,
      headerName: "",
      headerTooltip: "Select the button to navigate to the task",
      field: 'url',
      filter: false,
      suppressMenu: true,
      flex: 0.25,
      minWidth: 75,
      maxWidth: 75,
      sortable: false,
      headerClass:'wrap-header-text',
      cellRenderer: ArrowImageLinkRendererComponent,
      cellRendererParams: {
        imageTitle: 'View this task'
      } as IImageCellRendererParams
    } as ColDef);
    return colDefs;
  }

  protected sortData(data: any, sortModel: any): void {
    let allData: MyTaskData[] = data as MyTaskData[];

    for (let i = 0; i < sortModel.length; i++) {
      const currentSortColumn = sortModel[i];
      if (currentSortColumn.colId == this.DESCRIPTION_COLUMN_ID) {
        if (currentSortColumn.sort.toLowerCase() == this.SORT_ORDER_ASCENDING) {
          allData.sort((a, b) => (a.description ? a.description : '') < (b.description ? b.description : '') ? -1 : 1);
        } else {
          allData.sort((a, b) => (a.description ? a.description : '') > (b.description ? b.description : '') ? -1 : 1);
        }
      }
      if (currentSortColumn.colId == this.POSTED_DATE_COLUMN_ID) {
        if (currentSortColumn.sort.toLowerCase() == this.SORT_ORDER_ASCENDING) {
          allData.sort((a, b) => a.postedDate < b.postedDate ? -1 : 1);
        } else {
          allData.sort((a, b) => a.postedDate > b.postedDate ? -1 : 1);
        }
      }
      if (currentSortColumn.colId == this.DUE_DATE_COLUMN_ID) {
        if (currentSortColumn.sort.toLowerCase() == this.SORT_ORDER_ASCENDING) {
          allData.sort((a, b) => a.dueDate < b.dueDate ? -1 : 1);
        } else {
          allData.sort((a, b) => a.dueDate > b.dueDate ? -1 : 1);
        }
      }
      if (currentSortColumn.colId == this.TASK_NAME_COLUMN_ID) {
        if (currentSortColumn.sort.toLowerCase() == this.SORT_ORDER_ASCENDING) {
          allData.sort((a, b) => a.taskName < b.taskName ? -1 : 1);
        } else {
          allData.sort((a, b) => a.taskName > b.taskName ? -1 : 1);
        }
      }

      if (currentSortColumn.colId == this.APP_SOURCE_COLUMN_ID) {
        if (currentSortColumn.sort.toLowerCase() == this.SORT_ORDER_ASCENDING) {
          allData.sort((a, b) => a.appSource < b.appSource ? -1 : 1);
        } else {
          allData.sort((a, b) => a.appSource > b.appSource ? -1 : 1);
        }
      }
    }
  }
}
