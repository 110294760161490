<ng-container *ngIf="displayDashboard">
  <dynamic-dashboard  id="dashboard"  [dashboardConfig]="dashboardConfig()" [dashboardData]="dashboardData()" [templates]="templates" [customGridFrameworkComponents]="frameworkComponents"></dynamic-dashboard>
</ng-container>

<ng-template #employeeDirectory>
  <employee-directory [displayHeader]="true"></employee-directory>
</ng-template>

<ng-template #tasksTabContainer>
  <tasks-tab-container></tasks-tab-container>
</ng-template>
