import { ICellRenderer } from "ag-grid-enterprise";
import { Component } from "@angular/core";
import { IconSpriteModule } from "ng-svg-icon-sprite";
import { CommonModule } from "@angular/common";
import { IImageCellRendererParams } from "../models/iimage-cell-renderer-params";

@Component({
  selector: 'arrow-image-link-renderer',
  templateUrl: 'arrow-image-link-renderer.component.html',
  standalone: true,
  imports: [IconSpriteModule, CommonModule]
})
export class ArrowImageLinkRendererComponent implements ICellRenderer<IImageCellRendererParams> {

  params!: any;

  public url!: string;
  public title!: string;

  agInit(params: IImageCellRendererParams): void {
    this.title = params?.imageTitle;
    this.url = params?.data?.url;
  }

  refresh(params: any): boolean {
    return true;
  }
}
